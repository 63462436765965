import React, { useState } from 'react';
import { Providers } from '@microsoft/mgt-element';
import { CircularProgress, Button } from '@mui/material';
import * as microsoftTeams from "@microsoft/microsoft-graph-client";


const CheckoutBook = ({ onRequestClose, libraryId, barcode, bookTitle, startDate, endDate, parameters }) => {
  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [checkoutStatus, setCheckoutStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loanPeriod, setLoanPeriod] = useState('');

  const checkoutSuccessMessage = '以下の貸出が完了しました。';
  const checkoutFailureMessage = '以下の貸出はできませんでした。再度、借用操作を行ってください。';

  const bookId = `${barcode}@${parameters['accountDomian']}`;


  const handleCancel = () => {
    // 確認画面で「キャンセル」ボタンがクリックされたときの処理
    // 確認画面を非表示にする
    onRequestClose();
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const accessToken = await Providers.globalProvider.getAccessToken();
      const client = await microsoftTeams.Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        }
      });

      const meData = await client.api(`/me`).get();
      const bookData = await client.api(`/users/${bookId}/calendar`).get();

      const event = {
        subject: `${barcode}-${bookData.owner.name}-${meData.mail}`,
        start: {
          dateTime: `${startDate}`,
          timeZone: 'Asia/Tokyo'
        },
        end: {
          dateTime: `${endDate}`,
          timeZone: 'Asia/Tokyo'
        },
        isAllDay: 'false',
        showAs: 'free',
        responseRequested: 'true',
        attendees: [
          {
            emailAddress: {
              address: `${bookId}`,
              name: `${barcode}`
            },
            type: 'required',
          }
        ]
      };

      const responseData = await client.api(`/users/${libraryId}/events`).post(event);
      const eventId = responseData.id;
      const eventUid = responseData.iCalUId;

      setTimeout(async () => {
        try {
          // Resourceの予定一覧を取得
          const resourceEvents = await client.api(`/users/${bookId}/events`).get();
          const matchingEvent =  await resourceEvents.value.find(event => event.iCalUId === eventUid);

          if (matchingEvent) {
            const eventStartDate = new Date(resourceEvents.value[0].start.dateTime);
            const startDateLocal = new Date(eventStartDate.getTime() + ((9 * 60) * 60 * 1000));
            const startDate = startDateLocal.getFullYear() +'-'+ ('0' + (startDateLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + startDateLocal.getDate()).slice(-2);                  
            
            const eventEndDate = new Date(resourceEvents.value[0].end.dateTime);
            const endDateLocal = new Date(eventEndDate.getTime() + ((9 * 60) * 60 * 1000));
            const endDate = endDateLocal.getFullYear() +'-'+ ('0' + (endDateLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + endDateLocal.getDate()).slice(-2);

            setLoanPeriod(`${startDate} 〜 ${endDate}`);
            setCheckoutStatus(checkoutSuccessMessage);

            // checkout log output 
            const checkoutEvent = {
              fields: {
                eventID: `${responseData.id}`,
                Title: `${bookTitle}`,
                isbn: `${barcode}`,
                number: '1',
                user: `${meData.mail}`,
                userName: `${meData.displayName}`,
                checkoutStartDate: `${startDate}`,
                checkoutEndDate: `${endDate}`,
                status: 'checkout'
              }
            };

            await client.api(`/sites/${parameters['siteId']}/lists/${parameters['listId']}/items`)
              .post(checkoutEvent);

            const endDateEndLocal = new Date(eventEndDate.getTime() + ((24 * 60) * 60 * 1000) + ((9 * 60) * 60 * 1000));
            const endDateEnd = endDateEndLocal.getFullYear() +'-'+ ('0' + (endDateEndLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + endDateEndLocal.getDate()).slice(-2);
            
            const userReturnedEvent = {
              subject: `返却期限：${bookTitle}`,
              start: {
                dateTime: `${endDate}`,
                timeZone: 'Asia/Tokyo'
              },
              end: {
                dateTime: `${endDateEnd}`,
                timeZone: 'Asia/Tokyo'
              },
              isAllDay: 'true',
              showAs: 'free',
              responseRequested: 'false',
            };

            // const  userReturnedEventResponse= await client.api('/me/events').post(userReturnedEvent);
            // console.log('CB-responseData::',userReturnedEventResponse);
            await client.api('/me/events').post(userReturnedEvent);

          } else {
            setCheckoutStatus(checkoutFailureMessage);
            await client.api(`/users/${libraryId}/events/${eventId}`).delete();
          }

          setCheckoutComplete(true);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching resource events', error);
          setCheckoutStatus(checkoutFailureMessage);
          setCheckoutComplete(true);
          setLoading(false);
        }
      }, 5000);
      
    } catch (error) {
      console.error('Error POST data', error);
      setCheckoutStatus(checkoutFailureMessage);
      setCheckoutComplete(true);
      setLoading(false);
    }
  };

  return (
    <>
      {checkoutComplete ? (
        <div>
          <p>{checkoutStatus}</p>
          <div style={{width: "100%", textAlign: "left", marginTop: '10px'}}>
            {bookTitle}
          </div>
          <div style={{width: "100%", textAlign: "left", marginTop: '5px'}}>
            期間: {loanPeriod}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={onRequestClose}
            style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
            size="small"
          >
            CLOSE
          </Button>
        </div>
      ) : (
        <div>
          {loading ? (
            <div>
            <CircularProgress />
            <p>処理中…</p>
            </div>
          ) : (
            <>
              <div>
                <div style={{width: "100%", textAlign: "left", marginTop: '10px'}}>
                  {bookTitle}
                </div>
                <div style={{width: "100%", textAlign: "left", fontSize: "0.9em", marginTop: '5px'}}>
                  ISBN: {barcode}
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                style={{ padding: '6px 12px', marginTop: '20px', marginRight: '5px' }}
                size="small"
              >
                借りる
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
                style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
                size="small"
              >
                CANCEL
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CheckoutBook;
