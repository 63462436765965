import React, { useState, useEffect } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { Providers } from '@microsoft/mgt-element';
import { CircularProgress, Button, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as microsoftTeams from "@microsoft/microsoft-graph-client";


const ReturnBook = ({ onRequestClose, libraryId, barcode, bookTitle, parameters }) => {
  const [result, setResult] = useState(null);
  const [scanning, setScanning] = useState(true);
  const [scanner, setScanner] = useState(null);
  const [returnAddress, setReturnAddress] = useState(false);
  const [returnComplete, setReturnComplete] = useState(false);
  const [returnStatus, setReturnStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const returnSuccessMessage = '以下の返却が完了しました。';
  const returnFailureMessage = '以下の返却はできませんでした。再度、返却操作を行ってください。';
  const returnAddressFailureMessage = '返却場所が確認できませんでした。再度、返却操作を行ってください。';
  
  const bookId = `${barcode}@${parameters['accountDomian']}`;


  useEffect(() => {
    if (scanning) {
      const codeReader = new BrowserMultiFormatReader();
      codeReader.decodeFromVideoDevice(null, 'video', async (result, err) => {
        if (result) {
          const scannedBarcode = result.getText();
          setResult(scannedBarcode);

          if (result.text === parameters['libraryCode']){
            setReturnAddress(true);
          } 

          setScanning(false);
          codeReader.reset();
        }
        if (err && !(err instanceof NotFoundException)) {
          console.error(err);
        }
      });
      setScanner(codeReader);
    }
    return () => {
      if (scanner) {
        scanner.reset();
      }
    };
  }, [scanning]);

  const handleCancel = () => {
    // 確認画面で「キャンセル」ボタンがクリックされたときの処理
    // 確認画面を非表示にする
    setScanning(false);
    if (scanner) {
      scanner.reset();
    }
    onRequestClose();
  };

  const handleEventDeleteConfirm = async () => {
    setScanning(false);
    if (scanner) {
      scanner.reset();
    }

    setLoading(true);
    
    try {
      const accessToken = await Providers.globalProvider.getAccessToken();
      const client = await microsoftTeams.Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        }
      });

      const bookEvents = await client.api(`/users/${bookId}/events`).get();

      if (bookEvents.value.length > 0){
        const libraryEvents = await client.api(`/users/${libraryId}/events`).get();
        const matchingEvent =  await libraryEvents.value.find(event => event.iCalUId === bookEvents.value[0].iCalUId);

        await client.api(`/users/${libraryId}/events/${matchingEvent.id}`).delete();

        // extened loan log
        const logItemData = await client.api(`/sites/${parameters['siteId']}/lists/${parameters['listId']}/items`)
          .header('Prefer','allowthrottleablequeries')
          .filter(`fields/eventID eq '${matchingEvent.id}'`)
          .get();

        if (logItemData){
          const nowDate = new Date(); // Client location date
          const returnDate = nowDate.getFullYear() +'-'+ ('0' + (nowDate.getMonth()+1)).slice(-2) +'-'+ ('0' + nowDate.getDate()).slice(-2);

          const checkoutEvent = {
            fields: {
              returnDate: `${returnDate}`,
              status: 'returned'
            }
          };

          await client.api(`/sites/${parameters['siteId']}/lists/${parameters['listId']}/items/${logItemData.value[0].id}`).patch(checkoutEvent);

        }else{
          console.log('no maching logItem::',matchingEvent.id);
        };
        
      }

      setReturnComplete(true);
      setReturnStatus(returnSuccessMessage);
      setLoading(false);

    } catch (error) {
      console.error('Error POST data', error);
      setReturnComplete(true);
      setReturnStatus(returnFailureMessage);
      setLoading(false);
    }
  };

  return (
    <div className="scanner-container">
      {scanning && (
        <div className="scanner-container">
          <p><b>「書棚QRコード」を読み取ってください。</b></p>
          <video id="video" style={{ width: '100%', height: '65svh' }}></video>
          <div className="overlay">
            <div className="target"></div>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancel}
            style={{ padding: '6px 12px', marginTop: '20px' }}
            size="small"
          >
            CANCEL
          </Button>
        </div>
      )}
      {(result && returnAddress) ? (
        <>
          {returnComplete ? (
            <div>
              <p>{returnStatus}</p>
              <p>{bookTitle}</p>
              <Button
                variant="contained"
                color="primary"
                onClick={onRequestClose}
                style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
                size="small"
              >
                CLOSE
              </Button>
            </div>
          ) : (
            <>
              {loading ? (
                <>
                  <CircularProgress />
                  <p>処理中…</p>
                </>
              ) : (
                <>
                  <Box flexDirection="row" justifyContent="flex-end" display="flex">
                    <IconButton
                      color="primary"
                      onClick={handleCancel}
                      style={{ padding: '0px 0px' }}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box display="box">
                    <p>{bookTitle}</p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEventDeleteConfirm}
                      style={{ padding: '6px 12px', marginTop: '20px', marginRight: '5px' }}
                      size="small"
                    >
                      返却する
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCancel}
                      style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
                      size="small"
                    >
                      CANCEL
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {!scanning && (
            <>
              <p>{returnAddressFailureMessage}</p>
              <Button
                variant="contained"
                color="primary"
                onClick={onRequestClose}
                style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
                size="small"
              >
                CLOSE
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ReturnBook;
