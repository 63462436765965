import { Providers } from '@microsoft/mgt-element';
import { CacheService } from '@microsoft/mgt-react';
import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import Leaflet from "leaflet";
import EventSearchUrl from './EventSearchDate';

CacheService.config.presence.invalidationPeriod = 30000; // 30 seconds

const userPresence = {
  "Available": "makericon_green",
  "AvailableIdle": "makericon_green",
  "Busy": "makericon_red",
  "BusyIdle": "makericon_red",
  "DoNotDisturb": "makericon_red",
  "BeRightBack": "makericon_orange",
  "Away": "makericon_orange",
  "Offline": "makericon_gray",
  "None": "makericon_none",
  "": "makericon_gray",
};

const transparentImage = './images/transparent.png';
const booksImage = './images/book_tate.png';

// export default function UserStatusMarker(props) {
// export default function UserStatusMarker({ iconSize, workSpaceURL, usersURL, selectDate, hotDesking, usersList, workSpaceList }) {
export default function UserStatusMarker({ iconSize, selectDate, hotDesking, usersList, workSpaceList }) {

  const [userDataList, setUserDataList] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  // const [nowDate, setNowDate] = useState((new Date()).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }).split(' ')[0]);
  const [nowDate, setNowDate] = useState(null);

  // console.log('hotDesking::',hotDesking);
  // console.log('usersList::',usersList);
  // console.log('workSpaceList::',workSpaceList);

  const CustomMarker = ({ userData, presence }) => {
    const customIcon = new Leaflet.Icon({
      iconUrl: userData.photo,
      iconSize: [iconSize[0], iconSize[1]],
      iconAnchor: [iconSize[0] / 2, iconSize[1] / 2],
      className: userPresence[presence],
    });

    if (!userData || !userData.workspace.position[0] || iconSize[0] < 1 || userData.work === 'free') {
      return null;
    }

    const iconOffsetA = [7, 2];
    const iconOffsetB = [13, -2];
    const iconOffsetZ = [5, -5];

    let markerPosition = [];
    if (userData.workspace.desktype === 'A') {
      markerPosition = [
        (userData.workspace.position[0][0] - iconOffsetA[0]),
        ((userData.workspace.position[1][1] - userData.workspace.position[0][1]) / 2 + userData.workspace.position[0][1] - iconOffsetA[1])
      ];
    } else if (userData.workspace.desktype === 'B') {
      markerPosition = [
        (userData.workspace.position[1][0] + iconOffsetB[0]),
        ((userData.workspace.position[1][1] - userData.workspace.position[0][1]) / 2 + userData.workspace.position[0][1] + iconOffsetB[1])
      ];
    } else {
      markerPosition = [
        (userData.workspace.position[1][0] - userData.workspace.position[0][0]) / 2 + userData.workspace.position[0][0] + iconOffsetZ[0],
        ((userData.workspace.position[1][1] - userData.workspace.position[0][1]) / 2 + userData.workspace.position[0][1]) + iconOffsetZ[1]
      ];
    }

    return (
      <Marker position={markerPosition} icon={customIcon} />
    );
  }

  useEffect(() => {
    let ignore = false;

    const fetchUserData = async () => {
      try {
        const token = await Providers.globalProvider.getAccessToken();

        const users = await usersList.filter(us => us.userid);
        const workSpaceFixedUsers = await workSpaceList.filter(ws => ws.userid && ws.worktype ==='workspace' && ws.deskshare ==='fixed');
        const workSpaceHotUsers = await workSpaceList.filter(ws => ws.userid && ws.worktype ==='workspace' && ws.deskshare ==='hot');
        const workSpaceShareResources = await workSpaceList.filter(ws => ws.userid && ( ws.worktype === 'shareroom' || ws.worktype === 'equipment'));

        const userDataResults = [];

        // workSpaceFixedUsers -----
        if (workSpaceFixedUsers){
          const batchUsersRequests = [];
          for (let i = 0; i < workSpaceFixedUsers.length; i += 20) {
            const batch = workSpaceFixedUsers.slice(i, i + 20).map(wslist => {
              return {
                id: wslist.userid,
                method: "GET",
                url: `/users/${wslist.userid}/presence`
              };
            });

            batchUsersRequests.push(fetch(`https://graph.microsoft.com/v1.0/$batch`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ requests: batch })
            }));
          }

          const batchUsersResponses = await Promise.all(batchUsersRequests);
          const batchUsersResults = await Promise.all(batchUsersResponses.map(res => res.json()));

          for (const batchUsersResult of batchUsersResults) {
            for (const response of batchUsersResult.responses) {
              const wslist = workSpaceFixedUsers.find(ws => ws.userid === response.id);
              const [surname, givenName] = wslist.username.split(' ');
              
              if (response.status === 200) {
                const userPresenceData = response.body;

                const photoURL = () => {
                    return `https://ui-avatars.com/api/?name=${encodeURIComponent(surname)}+${encodeURIComponent(givenName)}&size=100&rounded=true&background=F5F7F9&color=4B4B4B`;
                }

                userDataResults.push({
                  comment: '',
                  photo: photoURL(),
                  userId: wslist.userid,
                  displayName: wslist.displayName,
                  userPrincipalName: wslist.userPrincipalName,
                  presence: userPresenceData,
                  work: "clockin",
                  workspace: {
                    deskid: wslist.deskid,
                    desktype: wslist.desktype,
                    position: wslist.position,
                    workstat: wslist.workstat
                  }
                });
              } else {
                userDataResults.push({
                  comment: '',
                  photo: transparentImage,
                  userId: wslist.userid,
                  displayName: wslist.displayName,
                  userPrincipalName: wslist.userPrincipalName,
                  presence: { "availability": "Offline", "activity": "Offline" },
                  work: "clockin",
                  workspace: {
                    deskid: wslist.deskid,
                    desktype: wslist.desktype,
                    position: wslist.position,
                    workstat: wslist.workstat
                  }
                });
              }
            }
          }
        }
        // ----- workSpaceFixedUsers

        // workSpaceHotUsers -----
        if (workSpaceHotUsers){
          const batchHotUsersRequests = [];
          for (let i = 0; i < workSpaceHotUsers.length; i += 20) {
            const batch = workSpaceHotUsers.slice(i, i + 20).map(wslist => {
              const eventOrganizerUrl = EventSearchUrl(wslist.userid,'select',new Date(), "short");
              // const eventOrganizerUrl = EventSearchUrl(wslist.userid, "used", "", "short")
              return {
                id: wslist.userid,
                method: "GET",
                url: eventOrganizerUrl
              };
            });

            batchHotUsersRequests.push(fetch(`https://graph.microsoft.com/v1.0/$batch`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ requests: batch })
            }));

          }

          const batchHotUsersResponses = await Promise.all(batchHotUsersRequests);
          const batchHotUsersResults = await Promise.all(batchHotUsersResponses.map(res => res.json()));

          const hotUserResults = [];
          for (const batchUsersResult of batchHotUsersResults) {
            for (const response of batchUsersResult.responses) {
              if (response.status === 200 && response.body.value.length) {

                // const today = new Date().toISOString();
                // console.log('response.body.value[0].organizer.emailAddress.address::',response.body.value[0].organizer.emailAddress.address);
                // if (response.body.value[0].start.dateTime <= today && today <= response.body.value[0].end.dateTime){
                  const wsList = workSpaceHotUsers.find(ws => ws.userid === response.id);
                  const wsUser = users.find(ws => ws.userPrincipalName === response.body.value[0].organizer.emailAddress.address);
                  // console.log('wslist::',wsList);
                  // console.log('wsuser::',wsUser);
                  if (wsUser){
                    hotUserResults.push({
                      id: response.id,
                      organizerId: wsUser.userid,
                      userid: wsList.userid,
                      username: wsUser.username
                    });
                  }
                // }
              }
            }
          }

          const batchUsersRequests = [];
          if (hotUserResults){
            for (let i = 0; i < hotUserResults.length; i += 20) {
              const batch = hotUserResults.slice(i, i + 20).map(hotlist => {
                return {
                  id: hotlist.id,
                  method: "GET",
                  url: `/users/${hotlist.organizerId}/presence`
                };
              });

              batchUsersRequests.push(fetch(`https://graph.microsoft.com/v1.0/$batch`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({ requests: batch })
              }));
            }

            const batchUsersResponses = await Promise.all(batchUsersRequests);
            const batchUsersResults = await Promise.all(batchUsersResponses.map(res => res.json()));
            
            for (const batchUsersResult of batchUsersResults) {
              for (const response of batchUsersResult.responses) {
                const wslist = workSpaceHotUsers.find(ws => ws.userid === response.id);
                const userProfile = users.find(up => up.userid === response.body.id);

                if (response.status === 200) {
                  const userPresenceData = response.body;

                  const photoURL = () => {
                    if (!userProfile.username) return;
                    const [surname, givenName] = userProfile.username.split(' ');
                    return `https://ui-avatars.com/api/?name=${encodeURIComponent(surname)}+${encodeURIComponent(givenName)}&size=100&rounded=true&background=F5F7F9&color=4B4B4B`;
                  }

                  userDataResults.push({
                    comment: 'hotdesk',
                    photo: photoURL(),
                    userId: wslist.userid,
                    displayName: wslist.displayName,
                    userPrincipalName: wslist.userPrincipalName,
                    presence: userPresenceData,
                    work: "clockin",
                    workspace: {
                      deskid: wslist.deskid,
                      desktype: wslist.desktype,
                      position: wslist.position,
                      workstat: wslist.workstat
                    }
                  });
                } else {
                  userDataResults.push({
                    comment: 'hotdesk',
                    photo: transparentImage,
                    userId: wslist.userid,
                    displayName: wslist.displayName,
                    userPrincipalName: wslist.userPrincipalName,
                    presence: { "availability": "Offline", "activity": "Offline" },
                    work: "clockin",
                    workspace: {
                      deskid: wslist.deskid,
                      desktype: wslist.desktype,
                      position: wslist.position,
                      workstat: wslist.workstat
                    }
                  });
                }
              }
            }
          }
        }

        // workSpaceShareResources -----
        if (workSpaceShareResources){
          const batchShareResourcesRequests = [];
          for (let i = 0; i < workSpaceShareResources.length; i += 20) {
            const batch = workSpaceShareResources.slice(i, i + 20).map(wslist => {
              const eventListUrl = () => {
                if (selectDate){
                  return EventSearchUrl(wslist.userid,'select',new Date(selectDate), "short");
                }else{
                  return EventSearchUrl(wslist.userid, "used", "", "short");
                }
              }

              return {
                id: wslist.userid,
                method: "GET",
                url: eventListUrl()
              };
            });

            batchShareResourcesRequests.push(fetch(`https://graph.microsoft.com/v1.0/$batch`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ requests: batch })
            }));
          }

          const batchShareResourcesResponses = await Promise.all(batchShareResourcesRequests);
          const batchShareResourcesResults = await Promise.all(batchShareResourcesResponses.map(res => res.json()));

          for (const batchShareResourcesResult of batchShareResourcesResults) {
            for (const response of batchShareResourcesResult.responses) {
              const wslist = workSpaceShareResources.find(ws => ws.userid === response.id);

              if (wslist.worktype === 'equipment'){
                userDataResults.push({
                  photo: booksImage,
                  userId: wslist.userid,
                  displayName: wslist.displayName,
                  userPrincipalName: wslist.userPrincipalName,
                  presence: {"availability": "None", "activity": "None"},
                  work: "clockin",
                  workspace: {
                    deskid: wslist.deskid,
                    desktype: wslist.desktype,
                    position: wslist.position,
                    workstat: wslist.workstat
                  }
                });
              }else{
                if (response.status === 200) {
                  const events = () => {
                    if (response.body.value[0]){
                      const today = new Date().toISOString();
                      // console.log('response.body.value[0].start.dateTime',response.body.value[0].start.dateTime);
                      if (response.body.value[0].start.dateTime <= today && today <= response.body.value[0].end.dateTime){
                        return 1;
                      }else{
                        return 0;
                      }
                    }else{
                      return 0;
                    }
                  }

                  userDataResults.push({
                    photo: transparentImage,
                    userId: wslist.userid,
                    displayName: wslist.displayName,
                    userPrincipalName: wslist.userPrincipalName,
                    presence: { "availability": "Offline", "activity": "Offline" },
                    work: "clockin",
                    workspace: {
                      deskid: wslist.deskid,
                      desktype: wslist.desktype,
                      position: wslist.position,
                      workstat: wslist.workstat
                    },
                    events: events(),
                    eventList: response.body.value.length
                  });
                } else {
                  userDataResults.push({
                    photo: transparentImage,
                    userId: wslist.userid,
                    displayName: wslist.displayName,
                    userPrincipalName: wslist.userPrincipalName,
                    presence: { "availability": "Offline", "activity": "Offline" },
                    work: "clockin",
                    workspace: {
                      deskid: wslist.deskid,
                      desktype: wslist.desktype,
                      position: wslist.position,
                      workstat: wslist.workstat
                    },
                    events: 0,
                    eventList: 0
                  });
                }
              }
            }
          }
        }
        // ----- workSpaceShareResources

        if (!ignore){
          setUserDataList(userDataResults);
        }

      } catch (error) {
        console.log('Error fetching profile data:', error);
      }
    };

    setNowDate((new Date()).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }).split(' ')[0]);
    fetchUserData();
    // const intervalIdData = setInterval(fetchUserData, 60000);
    const intervalIdData = setInterval(fetchUserData, 30000);
    setIntervalId(intervalIdData);

    return () => {
      clearInterval(intervalId);
      ignore = true;
    };

  // }, [nowDate, props.selectedLayer]);
  }, [nowDate, hotDesking, selectDate]);
  // }, [nowDate, selectDate]);
  // }, [hotDesking, selectDate]);

  const eventsToPresence = (desktype, workstat, presence, activeEvent, eventList) => {
    if (desktype === 'Z' && workstat !== 'equipment') {
      if (activeEvent > 0) {
        return 'Busy';
      } else if (activeEvent === 0 && eventList > 0) {
        return 'Away';
      } else {
        return 'Offline';
      }
    } else {
      return presence;
    }
  }

  return (
    <>
      {userDataList && userDataList.map((userData, index) => (
        <CustomMarker
          key={index}
          userData={userData}
          presence={eventsToPresence(
            userData.workspace.desktype,
            userData.workspace.workstat,
            userData.presence.availability,
            userData.events,
            userData.eventList
          )}
        />
      ))}
    </>
  );
};

