import React, { useState, useEffect } from 'react';
import { Providers } from '@microsoft/mgt-element';
import * as microsoftTeams from "@microsoft/microsoft-graph-client";
import moment from 'moment-timezone';
import Modal from 'react-modal';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress, Box } from '@mui/material';
import { Close, EventBusy } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    grey: {
      main: '#9e9e9e',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#f5f5f5',
          fontWeight: 'bold',
          padding: '4px 8px',
          textAlign: 'center',
        },
        body: {
          textAlign: 'center',
          padding: '4px 8px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '36px',
        },
      },
    },
  },
});

const ScheduleListModal = ({ onClose }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmCancelEvent, setConfirmCancelEvent] = useState(null);

  const handleFetchEvents = async () => {
    setLoading(true);
    try {
      const accessToken = await Providers.globalProvider.getAccessToken();
      const client = microsoftTeams.Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        }
      });

      const today = moment().tz('Asia/Tokyo').startOf('day').toISOString();
      const result = await client
        .api('/me/events')
        // .filter(`startswith(subject, '予約席：') and start/dateTime ge '${today}'`)
        .filter(`(startswith(subject, '予約席：') or startswith(subject, '予約済：')) and start/dateTime ge '${today}'`)
        .get();
      
      // 予約日の近い順にソート
      const sortedEvents = result.value.sort((a, b) => new Date(a.start.dateTime) - new Date(b.start.dateTime));
      setEvents(sortedEvents);
    } catch (error) {
      console.error('Error fetching events', error);
    }
    setLoading(false);
  };

  const handleCancelEvent = async () => {
    if (!confirmCancelEvent) return;

    setLoading(true);
    try {
      const accessToken = await Providers.globalProvider.getAccessToken();
      const client = microsoftTeams.Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        }
      });

      await client.api(`/me/events/${confirmCancelEvent.id}`).delete();
      setEvents(events.filter(event => event.id !== confirmCancelEvent.id));
      // 3秒待ってから元の画面に戻る
      setTimeout(() => {
        setLoading(false);
        setConfirmCancelEvent(null);
    }, 3000);
    } catch (error) {
      console.error('Error deleting event', error);
      setLoading(false);
      setConfirmCancelEvent(null);
    }
  };

  useEffect(() => {
    handleFetchEvents();
  }, []);

  const formattedTime = (data) => {
    const localStartTime = new Date(new Date(data.start.dateTime).getTime()+ ((24 * 60) * 60 * 1000) + ((9 * 60) * 60 * 1000)).toLocaleTimeString();
    const localEndTime = new Date(new Date(data.end.dateTime).getTime()+ ((24 * 60) * 60 * 1000) + ((9 * 60) * 60 * 1000)).toLocaleTimeString();

    if (new Date(data.start.dateTime).toLocaleTimeString() === "0:00:00" && new Date(data.end.dateTime).toLocaleTimeString() === "0:00:00"){
      return "終日";
    }else{
      return `${localStartTime.split(':')[0]}:${localStartTime.split(':')[1]}-${localEndTime.split(':')[0]}:${localEndTime.split(':')[1]}`;
    }
  };

  const reservationSeat = (subject) => {
    // const seatNumber = subject.replace('予約席：', '');
    const seatNumber = (subject.replace('：', ':')).split(':')[1];

    if (seatNumber.indexOf("ws") !== -1) {
      return `${seatNumber}席`;
    }else{
      return seatNumber;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: '10px' }}>
        <h2 style={{ margin: '10px 0' }}>予約済みの場所</h2>
        {loading ? (
          <p>読み込み中...</p>
        ) : (
          <TableContainer component={Paper} style={{ marginTop: '20px', maxHeight: '200px', overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>予約日</TableCell>
                  <TableCell>場所</TableCell>
                  <TableCell>取消</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map(event => {
                  // const seatNumber = event.subject.replace('予約席：', '');
                  const seatNumber = (event.subject.replace('：', ':')).split(':')[1];
                  const reservationDate = new Date(event.start.dateTime).toLocaleDateString();
                  const reservationTime = formattedTime(event);

                  return (
                    <TableRow key={event.id}>
                      <TableCell>
                        <Box>
                          {reservationDate}
                        </Box>
                        <Box>
                          {reservationTime}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 100,
                        }}
                      >
                        <Box
                          sx={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                          }}
                        >
                        {reservationSeat(event.subject)}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton 
                          onClick={() => setConfirmCancelEvent({ id: event.id, reservationDate, seatNumber, reservationTime })}
                          color="grey"
                        >
                          <EventBusy />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Button 
          variant="contained" 
          color="primary" 
          onClick={onClose} 
          startIcon={<Close />}
          style={{ margin: '20px 0', marginBottom: '5px' }}
        >
          閉じる
        </Button>

        {/* 確認モーダル */}
        <Modal
          isOpen={!!confirmCancelEvent}
          onRequestClose={() => setConfirmCancelEvent(null)}
          contentLabel="キャンセル確認"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress />
            <p>キャンセル中...</p>
          </div>
        ) : (
          <>
          <h2>キャンセル確認</h2>
          {confirmCancelEvent && (
            <p>{`${confirmCancelEvent.reservationDate} (${confirmCancelEvent.reservationTime}) の ${confirmCancelEvent.seatNumber} をキャンセルしますか`}</p>
          )}
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCancelEvent} 
            style={{ margin: '10px' }}
          >
            OK
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setConfirmCancelEvent(null)} 
            style={{ margin: '10px' }}
          >
            戻る
          </Button>
          </>
        )}
        </Modal>
      </div>
    </ThemeProvider>
  );
};

export default ScheduleListModal;
