const GetBookInfo = async (isbn) => {
    try {
      const response = await fetch(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`);
      const data = await response.json();
  
    //   console.log('Google Books API Response:', data); // 取得したデータをコンソールに出力
  
      if (data.totalItems === 0) {
        throw new Error('Book not found');
      }
  
      const book = data.items[0].volumeInfo;
      return {
        title: book.title || 'No Title',
        summary: book.description || 'No Description',
        author: book.authors ? book.authors.join(', ') : 'Unknown Author',
        isbn13: book.industryIdentifiers && book.industryIdentifiers.find(id => id.type === 'ISBN_13')
                ? book.industryIdentifiers.find(id => id.type === 'ISBN_13').identifier
                : isbn,
        bookImage: book.imageLinks?.thumbnail || '',
        location: "LIB0001"
      };
    } catch (error) {
      console.error('Error fetching book info:', error); // エラー時のログ出力
      alert('Book information not found. Please register manually.');
      return {
        title: '',
        author: '',
        summary: '',
        bookImage: '',
        location: 'LIB0001'
      };
    }
  };

  export default GetBookInfo;