import React, { useState, useRef, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { Container, TextField, Button, Box, Typography, Paper } from '@mui/material';

import GetBookInfo from './GetBookInfo';

const BookRegister = ({ onClose }) => {
    const [isbn, setIsbn] = useState('');
    const [book, setBook] = useState({
        title: '',
        author: '',
        summary: '',
        bookImage: '',
        location: ''
    });
    const [existingBook, setExistingBook] = useState(null); // 既存の本の情報を保持
    const [error, setError] = useState('');
    const [scanning, setScanning] = useState(false);
    const videoRef = useRef(null);

    const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

    useEffect(() => {
        if (scanning) {
            const codeReader = new BrowserMultiFormatReader();
            codeReader.decodeFromVideoDevice(null, videoRef.current, (result, err) => {
                if (result) {
                    setIsbn(result.getText());
                    setScanning(false);
                }
                if (err && err.name !== 'NotFoundException') {
                    console.error(err);
                    setError('Error scanning barcode');
                    setScanning(false);
                }
            });
            return () => {
                codeReader.reset();
            };
        }
    }, [scanning]);

    const handleFetchBook = async () => {
        try {
            const response = await fetch('./api/books', {
                headers: {
                    'Authorization': AUTH_TOKEN
                }
            });
            const books = await response.json();
            const existing = books.find(b => b.isbn13 === isbn);

            if (existing) {
                setExistingBook(existing);
                setBook({
                    title: existing.title || '',
                    author: existing.author || '',
                    summary: existing.summary || '',
                    bookImage: existing.bookImage || '',
                    location: existing.location || ''
                });
                setError('This book is already registered. You can edit or delete it.');
            } else {
                const bookInfo = await GetBookInfo(isbn);
                setBook(prevBook => ({
                    ...prevBook,
                    title: bookInfo.title || prevBook.title,
                    author: bookInfo.author || prevBook.author,
                    summary: bookInfo.summary || prevBook.summary,
                    bookImage: bookInfo.bookImage || prevBook.bookImage,
                    location: bookInfo.location || prevBook.location
                }));
                setExistingBook(null);
                setError('');
            }
        } catch (err) {
            console.error('Error:', err);
            setError(err.message);
            setBook(null);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBook(prevBook => ({
            ...prevBook,
            [name]: value
        }));
    };

    const handleRegisterBook = async () => {
        const url = existingBook
            ? `./api/update-book/${isbn}`
            : './api/register-book';
        const method = existingBook ? 'PUT' : 'POST';

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AUTH_TOKEN
            },
            body: JSON.stringify({ ...book, isbn13: isbn })
        });

        if (response.ok) {
            alert(existingBook ? 'Book updated successfully!' : 'Book registered successfully!');
        } else {
            alert('Failed to save book.');
        }
    };

    const handleDeleteBook = async () => {
        const response = await fetch(`./api/delete-book/${isbn}`, {
            method: 'DELETE',
            headers: {
                'Authorization': AUTH_TOKEN
            }
        });

        if (response.ok) {
            alert('Book deleted successfully!');
            setBook({
                title: '',
                author: '',
                summary: '',
                bookImage: '',
                location: ''
            });
            setIsbn('');
            setExistingBook(null);
            setError('');
        } else {
            alert('Failed to delete book.');
        }
    };

    return (
        <Container maxWidth="sm" style={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
            <Paper style={{ flex: 1, overflowY: 'auto', padding: '20px' }} elevation={3}>
                <Typography variant="h4" gutterBottom>
                    Register Book
                </Typography>
                 <Button variant="contained" color="secondary" onClick={() => setScanning(!scanning)} sx={{ mb: 2 }}>
                    {scanning ? 'Stop Scanning' : 'Scan Barcode'}
                </Button>
                {scanning && (
                    <Box sx={{ mb: 2 }}>
                        <video ref={videoRef} style={{ width: '100%', maxHeight: '200px' }} />
                    </Box>
                )}
                <TextField
                    fullWidth
                    label="Enter ISBN13"
                    value={isbn}
                    onChange={(e) => setIsbn(e.target.value)}
                    margin="normal"
                />
                <Button variant="contained" onClick={handleFetchBook} sx={{ mb: 2 }}>
                    Fetch Book Info
                </Button>
                {error && <Typography color="error">{error}</Typography>}
                {book && (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Book Information
                        </Typography>
                        <TextField
                            fullWidth
                            label="Title"
                            name="title"
                            value={book.title}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Author"
                            name="author"
                            value={book.author}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Summary"
                            name="summary"
                            value={book.summary}
                            onChange={handleInputChange}
                            margin="normal"
                            multiline
                            rows={4}
                        />
                        <TextField
                            fullWidth
                            label="BookImage"
                            name="bookImage"
                            value={book.bookImage}
                            onChange={handleInputChange}
                            margin="normal"
                            placeholder="Enter book image URL"
                        />
                        <Box mt={2} display="flex" alignItems="center">
                            <img src={book.bookImage} alt={book.title} style={{ maxWidth: '100px', marginRight: '20px' }} />
                        </Box>
                        <TextField
                            fullWidth
                            label="Location"
                            name="location"
                            value={book.location}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                        <Button variant="contained" color="primary" onClick={handleRegisterBook} sx={{ mt: 2 }}>
                            {existingBook ? 'Update Book' : 'Register Book'}
                        </Button>
                        {existingBook && (
                            <Button variant="contained" color="secondary" onClick={handleDeleteBook} sx={{ mt: 2, ml: 2 }}>
                                Delete Book
                            </Button>
                        )}
                    </Box>
                )}
                <Button variant="contained" onClick={onClose} sx={{ mt: 2 }}>
                    Close
                </Button>
            </Paper>
        </Container>
    );
};

export default BookRegister;