import DOMPurify from 'dompurify'; // $ npm i dompurify
import { Providers } from '@microsoft/mgt-element';
import { Person, CacheService } from '@microsoft/mgt-react';
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

import EventSearchUrl from './EventSearchDate';
import ReservationConfirmationModal from './ReservationConfirmationModal';
import OfficeLibrary from './OfficeLibrary';


CacheService.config.users.invalidationPeriod = 30000; // 30 seconds
CacheService.config.presence.invalidationPeriod = 10000; // 10 seconds

export default function UserDialog ({
  userid,
  work,
  workStat,
  desktype,
  userPresence,
  userStatusMessage,
  userEvent,
  searchDate,
  hotDesking,
  deskshare,
  worktype,
  comment,
  setReservedAction,
  parameters,
}) {
  
  const [presence, setPresence] = useState([]);
  const [profile, setProfile] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [nameAvatar, setNameAvatar] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);

  // 予約開始日を指定する
  const date1 = new Date(searchDate);
  const reservedDate = date1.getFullYear() +'-'+ ('0' + (date1.getMonth()+1)).slice(-2) +'-'+ ('0' + date1.getDate()).slice(-2);
  const startDate = reservedDate +'T00:00:00';

  // 予約終了日を指定する
  const date2 = new Date(searchDate);
  const date3 = new Date(date2.setDate(date2.getDate() +1));
  const endDate = date3.getFullYear() +'-'+ ('0' + (date3.getMonth()+1)).slice(-2) +'-'+ ('0' + date3.getDate()).slice(-2) +'T00:00:00';

  const selectDate = (new Date(searchDate)).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }).split(' ')[0];

// console.log('comment::',comment);
  const toDay = () => {
    if (hotDesking){
      return searchDate;
    }else{
      return null;
    }
  }
  
  const userPrincipalName = userid; // 対象のユーザーの userPrincipalName を指定

  useEffect(() => {
    const fetchPresenceAndProfile = async () => {
      try {
        const token = await Providers.globalProvider.getAccessToken();

        // Microsoft Graph APIから指定したユーザーのプロファイル情報を取得
        const profileResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${userPrincipalName}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (profileResponse.ok) {
          const profileData = await profileResponse.json();
          setProfile(profileData);

          if (desktype !== 'Z'){
            // Microsoft Graph APIから指定したユーザーのプレゼンス情報を取得
            const presenceResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${profileData.id}/presence`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (presenceResponse.ok) {
              const presenceUserData = await presenceResponse.json();
              setPresence(presenceUserData);
              
              if (presenceUserData.statusMessage){
                const sanitizedMessage = DOMPurify.sanitize(presenceUserData.statusMessage.message.content);
                const styledSanitizedMessage = sanitizedMessage.replace(/<p>/g, '<p style="margin: 0;">');
                setStatusMessage(styledSanitizedMessage);
              }
            } else {
              // console.error('Failed to fetch presence data');
              console.log('Failed to fetch presence data::',profileData.id);
            }
          }

          // Microsoft Graph APIから指定したユーザーのプロファイル写真（アバター）を取得
          const photoResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${profileData.id}/photo/$value`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (photoResponse.ok) {
            // Blobから画像URLを生成してstateにセット
            const photoBlob = await photoResponse.blob();
            const photoURL = URL.createObjectURL(photoBlob);
            setPhoto(photoURL);
          } 

          if (profileData.surname){
            setNameAvatar(profileData.surname + " " + profileData.givenName);
          }else{
            setNameAvatar(profileData.displayName)
          }

          const calendarViewUrl = EventSearchUrl(userPrincipalName,'select',toDay());

          const eventsResponse = await fetch(calendarViewUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
          });

          if (eventsResponse.ok) {
            const eventListData = await eventsResponse.json();

            setEventData(eventListData.value);

            if (desktype === 'Z'){
              if (workStat !== 'equipment'){
                // const activeEventUrl = EventSearchUrl(profileData.id,'used');
                const activeEventUrl = () => {
                  // if (selectDate){
                  if (hotDesking){
                    return EventSearchUrl(profileData.id,'select',new Date(searchDate),"");
                  }else{
                    return EventSearchUrl(profileData.id,'used',"","");
                  }
                }

                // const activeEventResponse = await fetch(activeEventUrl, {
                const activeEventResponse = await fetch(activeEventUrl(), {
                  headers: {
                      Authorization: `Bearer ${token}`,
                    },
                });

                if (activeEventResponse.ok) {
                  const eventsUserData = await activeEventResponse.json();

                  const events = () => {
                    if (eventsUserData.value[0]){
                      const today = new Date().toISOString();

                      if (eventsUserData.value[0].start.dateTime <= today && today <= eventsUserData.value[0].end.dateTime){
                        return 1;
                      }else{
                        return 0;
                      }

                    }else{
                      return 0;
                    }
                  }

                  const availability = eventsToPresence(desktype, 'Offline', events(), eventsUserData.value.length);
                  setPresence({"activity":availability, "availability":availability});
                }else{
                  setPresence({"activity":"Offline", "availability":"Offline"});
                }
              }else{
                setPresence({"activity":"Offline", "availability":"Offline"});
              }
            }
          }

        } else {
          // console.error('Failed to fetch user profile');
          console.log('Failed to fetch user profile::',userPrincipalName);
        }

      } catch (error) {
        // console.error('Error fetching data', error);
        console.log('Error fetching data::', error);
      }
    };

    // 初回のデータ取得
    fetchPresenceAndProfile();

  }, [userPresence, userStatusMessage, userEvent]);

  const dateTimeToFormatted = ({ dateTime, timeZone }) => {
    if (timeZone === 'UTC'){
      // dateTime = dateTime + 'Z';
      dateTime += 'Z';
    }

    const localDateTime = (new Date(dateTime)).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });

    const localTime = localDateTime.split(' ')[1];
    // const [hour, minute, second] = localTime.split(':'); // コロン（:）で分割して時、分、秒を取得
    const formattedTime = `${localTime.split(':')[0]}:${localTime.split(':')[1]}`; // 時：分のフォーマットで時間を結合

    return formattedTime;
  };

  const attendeesList = ({attendees, userPrincipalName}) => {

    const list = attendees.map((item, index) => {
      if (item.emailAddress.address === userPrincipalName || item.type === "resource" || item.emailAddress.name.startsWith('ws')){ // リソースアカウントを削除する場合
        return null;
      }

      return (
        <div key={index} style={{padding:"1px"}}>
          <Person
            fallbackDetails={{"displayName":item.emailAddress.name}}
            avatarType={"initials"}
          />
        </div>
      );
    });

    return list;

  };

  const EventViewFormat = ({eventItem, userPrincipalName, desktype}) => {
    const startTime = dateTimeToFormatted( {dateTime:eventItem.start.dateTime, timeZone:eventItem.start.timeZone});
    const endTime = dateTimeToFormatted( {dateTime:eventItem.end.dateTime, timeZone:eventItem.end.timeZone});
    const attendeesListData = attendeesList( {attendees:eventItem.attendees, userPrincipalName:userPrincipalName} );

    const locationName = () => {
      if (desktype === 'Z'){
        return '';
      }else{
        return eventItem.location.displayName.split('; ').map(location => 
          location.includes("Microsoft Teams") ? "Teams会議" : location
        ).join('; ');
      }
    };

    return(
      <>
        <div className="schedule-item">
          <div>
            <span className="schedule-time">{ eventItem.isAllDay ? " (終日)" : startTime+"-"+endTime } </span>
            <span className="schedule-event">{eventItem.subject}</span>
            <div style={{marginLeft:"18px"}}> 
              {/* {eventItem.location.displayName && desktype !== 'Z' ? eventItem.location.displayName : ''}  */}
              {locationName()}
            </div>
            <div style={{display: "flex", flexWrap: "wrap", marginLeft:"18px"}}>
              {attendeesListData}
            </div>
          </div>
        </div>
      </>
    );
  };

  const personAvatar = () => {
    let isPresense = true;
    // if (work === 'clockout' || presence.availability === 'Offline' || hotDesking){
    if ((work === 'clockout' || presence.availability === 'Offline' || workStat === 'equipment' || (hotDesking && workStat !== 'shareroom') )){
      isPresense = false;
    }

    if (profile === undefined || profile === null){
      return;
    }else{
      if (workStat === 'equipment' || workStat === 'shareroom'){
        return (
          <Person 
            view={"twolines"}
            fallbackDetails={{"displayName":nameAvatar}}
            showPresence={isPresense}
            personPresence={{"activity":presence.available, "availability":presence.availability, "id":null}}
            avatarType={"initials"}
            // personImage={photo}
          />
        );
      }else{
        
        return (
            <Person 
              view={"twolines"}
              fallbackDetails={{"displayName":nameAvatar,"mail":profile.mail}}
              showPresence={isPresense}
              personPresence={{"activity":presence.available, "availability":presence.availability, "id":null}}
              // avatarType={"initials"}
              personImage={photo}
            />
        )
      }
    }
  }

  // UserStatusMaker.js --------
  const eventsToPresence = (desktype, presence, activeEvent, eventList) =>{
    if (desktype === 'Z'){
      if (activeEvent > 0){
        return ('Busy');
      }
      else if (activeEvent === 0 && eventList > 0){
        return ('Away');
      }
      else{
        return ('Offline');
      }
    }else{
      return presence;
    }
  }
  // -------- UserStatusMaker.js

  const eventList = () => {
    return (
      <>
        {hotDesking ? (
          <div style={{fontWeight: "bold", marginTop:"5px", marginBottom:"5px"}}>{selectDate}の予定：</div>
          ):(
          <div style={{fontWeight: "bold", marginTop:"5px", marginBottom:"5px"}}>今日の予定：</div>
          )
        }
        <div>
          { eventData && 
            eventData.map((item, index) => (
              <div key={index} style={{marginBottom:"5px"}}>
                <EventViewFormat eventItem={item} userPrincipalName={profile.mail} desktype={desktype}/>
              </div>
            ))
          }
        </div>
      </>
    );
  };

  // -------------
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleReserveClick = () => {
    // 予約ボタンがクリックされたとき、確認画面を表示
    setShowConfirmation(true);
  };

  const handleCloseModal = () => {
    // 確認画面で「閉じる」ボタンがクリックされたときの処理
    setShowConfirmation(false);
  };

  const [showShareRoomConfirmation, setShowShareRoomConfirmation] = useState(false);
  const [reservationShareRoomComplete, setReservationShareRoomComplete] = useState(false);

  const handleShareRoomReserveClick = () => {
    // 予約ボタンがクリックされたとき、確認画面を表示
    setShowShareRoomConfirmation(true);
  };

  const handleConfirmShareRoomReservation = () => {
    // 完了画面を表示する
    setReservationShareRoomComplete(true);
  };

  const handleCancelShareRoomReservation = () => {
    // 確認画面で「キャンセル」ボタンがクリックされたときの処理
    // 確認画面を非表示にする
    setShowShareRoomConfirmation(false);
  };

  const handleShareRoomCloseModal = () => {
    // 確認画面で「閉じる」ボタンがクリックされたときの処理
    setShowShareRoomConfirmation(false);
    setReservationShareRoomComplete(false);
    setReservedAction(prev => !prev);
  };
  
  return (
    <>
      <div>
        <div>
        <div style={{display: "flex"}}>
          <div>
            {personAvatar()}
          </div>
          </div>
          <div style={{display: "flex", marginTop:"5px"}}>
            <div style={{width: "17%", textAlign: "center"}}>
              { !((hotDesking && workStat !== 'shareroom') || workStat === 'equipment') && 
                presence.availability
              }
            </div>
          </div>

          <hr width="90%" align="left"/>
          {desktype !== 'Z' &&
            <div style={{display: "block", marginTop:"5px"}}>
              <div style={{fontWeight: "bold", marginBottom:"5px"}}>メッセージ：</div>
              <div style={{marginLeft:"6px"}} dangerouslySetInnerHTML={{ __html: statusMessage }} />
            </div>
          }
          {(comment &&
            <div style={{display: "block", marginTop:"5px"}}>
              <div style={{marginRight:"6px"}} dangerouslySetInnerHTML={{ __html: comment }} />
            </div>
          )}

        </div>
        <div style={{ width: "280px"}}>
          <div>
            {workStat !== 'equipment' && eventList()}
          </div>
          <div>
            {workStat === 'equipment' && 
              <div style={{display: "block", marginTop:"5px"}}>
                {!showConfirmation &&
                <div style={{textAlign: "center"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReserveClick}
                    style={{ padding: '4px 8px', margin: '0 5px', marginTop: '5px' }}
                    size="small"
                  >
                    入館
                  </Button>
                </div>
                }
              </div>
            }
            <OfficeLibrary
              isOpen={showConfirmation}
              onRequestClose={handleCloseModal}
              libraryId={userid}
            />
          </div>
          <div>
            {(workStat === 'shareroom' && deskshare === 'hot') &&
              <div style={{display: "block", marginTop:"5px"}}>
                {!showConfirmation &&
                <div style={{textAlign: "center"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShareRoomReserveClick}
                    style={{ padding: '4px 8px', margin: '0 5px', marginTop: '5px' }}
                    size="small"
                  >
                    予約
                  </Button>
                </div>
                }
              </div>
            }
            {profile ? (
              <ReservationConfirmationModal
              isOpen={showShareRoomConfirmation}
              onRequestClose={handleShareRoomCloseModal}
              onConfirm={handleConfirmShareRoomReservation}
              onCancel={handleCancelShareRoomReservation}
              reservedDate={selectDate}
              reservedAmPm=""
              startDate={startDate}
              endDate={endDate}
              deskid=""
              userPrincipalName={profile.mail}
              displayName={nameAvatar}
              worktype={worktype}
              reservationComplete={reservationShareRoomComplete}
              parameters={parameters}
            />
            ):(
              <ReservationConfirmationModal
                isOpen={showShareRoomConfirmation}
                onRequestClose={handleShareRoomCloseModal}
                onConfirm={handleConfirmShareRoomReservation}
                onCancel={handleCancelShareRoomReservation}
                reservedDate={selectDate}
                reservedAmPm=""
                startDate={startDate}
                endDate={endDate}
                deskid=""
                userPrincipalName=""
                displayName=""
                worktype={worktype}
                reservationComplete={reservationShareRoomComplete}
                parameters={parameters}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );

};

