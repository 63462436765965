// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* .library-bookImage {
  width: 5px;
  height: 8px;
} */

.library-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.library-modal-content {
  background: #fff;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/styles/OfficeLibrary.css"],"names":[],"mappings":";AACA;;;GAGG;;AAEH;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,cAAc;EACd,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":["\n/* .library-bookImage {\n  width: 5px;\n  height: 8px;\n} */\n\n.library-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100svh;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.library-modal-content {\n  background: #fff;\n  margin: 10px;\n  padding: 20px;\n  border-radius: 8px;\n  text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
