import React, { useState, useEffect } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { Button, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Providers } from '@microsoft/mgt-element';
import * as microsoftTeams from "@microsoft/microsoft-graph-client";


function BarcodeReader({ onRequestClose, setBarcode, onCheckoutBookOpen, onExtendBookOpen, onReturnBookOpen, parameters }) {
  const [result, setResult] = useState(null);
  const [scanning, setScanning] = useState(true);
  const [scanner, setScanner] = useState(null);
  const [bookTitle, setBookTitle] = useState('');
  const [availability, setAvailability] = useState('');
  const [extendLoan, setExtendLoan] = useState(null);
  const [loanPeriod, setLoanPeriod] = useState('');

  const notFoundBookMessage = '書籍を確認できませんでした。再度、書籍バーコードの読み取り操作を行ってください。';


  useEffect(() => {
    if (scanning) {
      const codeReader = new BrowserMultiFormatReader();
      codeReader.decodeFromVideoDevice(null, 'video', async (result, err) => {
        if (result) {
          const scannedBarcode = result.getText();
          setResult(scannedBarcode);
          setBarcode(scannedBarcode);

          try {
            const response = await fetch(parameters['booksUrl'], {headers: {'Authorization': parameters['AUTH_TOKEN'] }});
            const books = await response.json();
            const foundBook = books.find(
              (book) => book.isbn13 === scannedBarcode
            );

            if (foundBook) {

              try {
                const accessToken = await Providers.globalProvider.getAccessToken();
                const client = await microsoftTeams.Client.init({
                  authProvider: (done) => {
                    done(null, accessToken);
                  }
                });
                
                // Resourceの予定一覧を取得
                const resourceEvents = await client.api(`/users/${scannedBarcode}@${parameters['accountDomian']}/events`).get();

                if (resourceEvents.value.length > 0){
                  const eventStartDate = new Date(resourceEvents.value[0].start.dateTime);
                  const startDateLocal = new Date(eventStartDate.getTime() + ((9 * 60) * 60 * 1000));
                  const startDate = startDateLocal.getFullYear() +'-'+ ('0' + (startDateLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + startDateLocal.getDate()).slice(-2);                  
                  
                  const eventEndDate = new Date(resourceEvents.value[0].end.dateTime);
                  const endDateLocal = new Date(eventEndDate.getTime() + ((9 * 60) * 60 * 1000));
                  const endDate = endDateLocal.getFullYear() +'-'+ ('0' + (endDateLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + endDateLocal.getDate()).slice(-2);

                  const loanDays =  (new Date(resourceEvents.value[0].end.dateTime) - new Date(resourceEvents.value[0].start.dateTime)) / 86400000;
                  
                  setExtendLoan(loanDays);
                  setLoanPeriod(`${startDate} 〜 ${endDate}`);
                  setAvailability('onLoan')
                }else{
                  setAvailability('');
                }
                setBookTitle(foundBook.title);

              } catch (error) {
                console.error('Error event data', error);
                setAvailability('notFound');
                setBookTitle(notFoundBookMessage);
              }

            } else {
              setAvailability('notFound');
              setBookTitle(notFoundBookMessage);
            }

          } catch (error) {
            // console.error('Error fetching books:', error);
            // setBookTitle('Error fetching book data');
            setAvailability('notFound')
            setBookTitle(notFoundBookMessage);
          }

          setScanning(false);
          codeReader.reset();
        }
        if (err && !(err instanceof NotFoundException)) {
          console.error(err);
        }
      });
      setScanner(codeReader);
    }
    return () => {
      if (scanner) {
        scanner.reset();
      }
    };
  }, [scanning]);

  const handleCancel = () => {
    setScanning(false);
    setBarcode(null);
    if (scanner) {
      scanner.reset();
    }
    onRequestClose();
  };

  const handleCheckoutBookConfirm = () => {
    setScanning(false);
    if (scanner) {
      scanner.reset();
    }
    onRequestClose();
    onCheckoutBookOpen(bookTitle);
  };
  
  const handleExtendBookConfirm = () => {
    setScanning(false);
    if (scanner) {
      scanner.reset();
    }
    onRequestClose();
    // onExtendBookOpen(bookTitle, extendLoan);
    onExtendBookOpen(bookTitle);
  };

  const handleReturnBookConfirm = () => {
    setScanning(false);
    if (scanner) {
      scanner.reset();
    }
    onRequestClose();
    onReturnBookOpen(bookTitle);
  };


  return (
    <div className="scanner-container">
      {scanning && (
        <div className="scanner-container">
          <p><b>「書籍裏表紙のバーコード」を読み取ってください。</b></p>
          <video id="video" style={{ width: '100%', height: '65svh' }}></video>
          <div className="overlay">
            <div className="target"></div>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancel}
            style={{ padding: '6px 12px', marginTop: '20px' }}
            size="small"
          >
            CANCEL
          </Button>
        </div>
      )}
      {result && (
        <div>
          <Box flexDirection="row" justifyContent="flex-end" display="flex">
            <IconButton
              color="primary"
              onClick={handleCancel}
              style={{ padding: '0px 0px' }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {availability === 'onLoan' && (
            <>
              <p>{bookTitle}</p>
              <p>貸出中</p>
              <p>{loanPeriod}</p>
              {/* <p>extendLoan: {extendLoan}</p> */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleReturnBookConfirm}
                style={{ padding: '6px 12px', marginTop: '20px', marginRight: '10px' }}
                size="small"
              >
                返却
              </Button>
              {extendLoan < parameters['maxLoanDays'] && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleExtendBookConfirm}
                    style={{ padding: '6px 12px', marginTop: '20px' }}
                    size="small"
                  >
                    延長
                  </Button>
 
                </>
              )}
            </>
          )}
          {availability === 'notFound' && (
            <>
              <p>{bookTitle}</p>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
                style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
                size="small"
              >
                CLOSE
              </Button>
            </>
          )}
          {!availability && (
            <>
              <p>{bookTitle}</p>
              <p>貸出可</p>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckoutBookConfirm}
                style={{ padding: '6px 12px', marginTop: '20px', marginRight: '5px' }}
                size="small"
              >
                借用
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BarcodeReader;
