import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Button, TextField, IconButton } from '@mui/material';
import CropFreeIcon from '@mui/icons-material/CropFree';
import InfoIcon from '@mui/icons-material/Info';
import { Providers } from '@microsoft/mgt-element';
import * as microsoftTeams from "@microsoft/microsoft-graph-client";

import BarcodeReader from './BarcodeReader';
import CheckoutBook from './CheckoutBook';
import ExtendBook from './ExtendBook';
import ReturnBook from './ReturnBook';

import './styles/OfficeLibrary.css';


const  parameters = {
  'loanDays' : 14,
  'extendDays' : 14,
  'maxLoanDays' : 28,
  'accountDomian' : 'mtf.co.jp',
  'libraryCode' : 'Px4xeNeRthPqz5Pgx4xmqGPmm0j1xu',
  'AUTH_TOKEN' : process.env.REACT_APP_AUTH_TOKEN,
  //'booksUrl' : './api/books.json', // local
  //'siteId' : 'e3b78e70-d90f-49f3-90f6-4a87d98df1b5,986e1c61-6b63-4b3e-b880-cbefab00fd78', // local
  //'listId' : '673a11b0-2248-4726-a833-3918a2442817', // local
  'booksUrl' : './api/books',
  'siteId' : 'c0e87101-b838-4a10-88eb-7c00a01bbd93,581dfc38-47d8-4376-bdf7-b36353c2a14c',
  'listId' : '90f2640a-6769-4fd1-90db-f1603ebd019a',
  
};


const OfficeLibrary = ({ isOpen, onRequestClose, libraryId }) => {
  const [bookDetailOpen, setBookDetailOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [books, setBooks] = useState([]);
  const [barcode, setBarcode] = useState(null);
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false);
  const [checkoutBookModalOpen, setCheckoutBookModalOpen] = useState(false);
  const [extendBookModalOpen, setExtendBookModalOpen] = useState(false);
  const [returnBookModalOpen, setReturnBookModalOpen] = useState(false);
  const [bookTitle, setBookTitle] = useState('');
  const [availability, setAvailability] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [loanBooks, setLoanBooks] = useState('');
  const [infoModalOpen, setInfoModalOpen] = useState(false);  // インフォメーションモーダルの状態


  useEffect(() => {
    fetch(parameters['booksUrl'], {headers: {'Authorization': parameters['AUTH_TOKEN'] }})
      .then(response => response.json())
      .then(data => setBooks(data))
      .catch(error => console.error('Error fetching books:', error));
  }, []);

  useEffect(() => {
    const fetchLoanBooks = async () => {
      try {
        const accessToken = await Providers.globalProvider.getAccessToken();
        const client = await microsoftTeams.Client.init({
          authProvider: (done) => {
            done(null, accessToken);
          }
        });

        const logItemData = await client.api(`/sites/${parameters['siteId']}/lists/${parameters['listId']}/items?expand=fields(select=isbn)`)
          .header('Prefer','allowthrottleablequeries')
          .filter(`fields/status ne 'returned'`)
          .get();

        setLoanBooks(logItemData.value);
      } catch (error) {
        console.error('Error fetchLoanBooks data', error);
      }

    }

    fetchLoanBooks()
  }, [isOpen, barcodeModalOpen, extendBookModalOpen, returnBookModalOpen]);

  const returnedCheck = (isbn) => {
    if (loanBooks){
      const isbnExists = loanBooks.some(item => item.fields.isbn === isbn);

      if (isbnExists) {
        return true;
      } else {
        return false;
      }
    }
  }

  const shortlensummary = (contents) => {
    if (contents.length > 45) {
      const shortcontents = contents.slice(0, 45) + '...';
      return shortcontents;
    }else{
      return contents;
    }
  };

  const shortlenTitle = (contents) => {
    if (contents.length > 16) {
      const shortcontents = contents.slice(0, 16) + '...';
      return shortcontents;
    }else{
      return contents;
    }
  };

  const closeBookList = () => {
    setSearchQuery('');
    onRequestClose();
  };

  const handleBooksDetail = (book) => {
    setSelectedBook(book);
    bookLoanStatus(book);
    setBookDetailOpen(true);
  };

  const closeBookDetail = () => {
    setBookDetailOpen(false);
    setSelectedBook(null);
  };
  
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBooks = books.filter((book) => {
    return (
      book.title.includes(searchQuery) ||
      book.author.includes(searchQuery) ||
      book.isbn13.includes(searchQuery) ||
      book.summary.includes(searchQuery)
    );
  });

  const openBarcodeModal = () => {
    setBarcodeModalOpen(true);
  };

  const closeBarcodeModal = () => {
    setBarcodeModalOpen(false);
  };

  const bookLoanStatus = async (book) => {
    try {
      const accessToken = await Providers.globalProvider.getAccessToken();
      const client = await microsoftTeams.Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        }
      });
      
      // Resourceの予定一覧を取得
      const resourceEvents = await client.api(`/users/${book.isbn13}@${parameters['accountDomian']}/events`).get();

      if (resourceEvents.value.length > 0){
        const eventEndDate = new Date(resourceEvents.value[0].end.dateTime);
        const endDateLocal = new Date(eventEndDate.getTime() + ((9 * 60) * 60 * 1000));
        const endDate = endDateLocal.getFullYear() +'-'+ ('0' + (endDateLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + endDateLocal.getDate()).slice(-2);

        setReturnDate(endDate);
        setAvailability('onLoan');
      }else{
        setAvailability('free');
        setReturnDate('')
      }

    } catch (error) {
      console.error('Error event data', error);
      setAvailability('');
      setReturnDate('');
    }
  }
  
  // 予約開始日を指定する
  const nowDate = new Date();
  const startDate = nowDate.getFullYear() +'-'+ ('0' + (nowDate.getMonth()+1)).slice(-2) +'-'+ ('0' + nowDate.getDate()).slice(-2) +'T00:00:00';

  // 予約終了日を指定する
  const dueDate = new Date(nowDate.setDate(nowDate.getDate() + parameters['loanDays']));
  const endDate = dueDate.getFullYear() +'-'+ ('0' + (dueDate.getMonth()+1)).slice(-2) +'-'+ ('0' + dueDate.getDate()).slice(-2) +'T00:00:00';

  const openCheckoutBookModal = (bookTitle) => {
    setBookTitle(bookTitle);
    setCheckoutBookModalOpen(true);
  };

  const closeCheckoutBookModal = () => {
    setCheckoutBookModalOpen(false);
  };

  const openExtendBookModal = (bookTitle) => {
    setBookTitle(bookTitle);
    setExtendBookModalOpen(true);
    setReturnBookModalOpen(false);
  };

  const closeExtendBookModal = () => {
    setExtendBookModalOpen(false);
  };

  const openReturnBookModal = (bookTitle) => {
    setBookTitle(bookTitle);
    setExtendBookModalOpen(false);
    setReturnBookModalOpen(true);
  };

  const closeReturnBookModal = () => {
    setReturnBookModalOpen(false);
  };

  const openInfoModal = () => {
    setInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };
  
  return (
    <div>
      {/* 書籍一覧モーダル */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeBookList}
        contentLabel="LibraryList"
        className="library-modal-content"
        overlayClassName="library-modal-overlay"
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
            <TextField
              variant="outlined"
              placeholder="書籍を検索"
              value={searchQuery}
              onChange={handleSearch}
              style={{ width: '60%', marginTop: '5px', marginBottom: '5px' }}
              size="small"
            />
            <Button
              variant="contained"
              color="success"
              onClick={openBarcodeModal}
              startIcon={<CropFreeIcon />}
              style={{ width: '35%',marginLeft: '5px', marginTop: '5px', height: '39px' }}  // ボタンの高さをTextFieldと揃える
              size="small"
            >
              貸出／返却
            </Button>
          </div>
          {/* <div style={{width: "100%", textAlign: "right", fontSize: "0.9em", fontWeight: 'bold'}}> */}
          <div style={{ display: "flex", marginBottom: '5px' }}>
            <div style={{width: "100%", textAlign: "left", fontSize: "0.9em", fontWeight: 'bold'}}>
              書籍：{books.length}冊（貸出中：{loanBooks.length}冊）
            </div>
          </div>
          <div style={{ maxHeight: '360px', overflowY: 'auto' }}>
            {filteredBooks.map((book, index) => (     
              <div key={index} style={{ display: "flex", marginTop: "5px", width: "100%", }}>
                {returnedCheck(book.isbn13)}
                {/* <div style={{marginRight:"5px"}}> */}
                <div style={{ marginRight: "5px", position: 'relative' }}>
                  <Button 
                    onClick={() => handleBooksDetail(book)} 
                    variant="outlined"
                    sx={{ 
                      padding: 0,
                      minWidth: '55px',
                      borderRadius: '3px',
                      overflow: 'hidden',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <img src={book.bookImage} alt="" width="55" height="80" />
                    {/* <img src={book.bookImage} alt="" width="55" height="80" style={{ border: '1px solid #d3d3d3', borderRadius: '3px' }} /> */}
                    {/* 貸出中の本は「貸出中」を表示 */}
                    {returnedCheck(book.isbn13) && (
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        zIndex: 1, // テキストが画像の上に表示されるように
                      }}>
                        貸出中
                      </div>
                    )}
                  </Button>
                </div>
                <div>
                  <div style={{ display: "box", inlineSize: "260px" }}>
                    <div>
                    <Button
                      onClick={() => handleBooksDetail(book)}
                      variant="text"
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        color: "black",
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        },
                        padding: 0,
                        textTransform: "none",
                        display: 'block',
                      }}
                    >
                      {shortlenTitle(book.title)}
                    </Button>
                    </div>
                    <div style={{width: "100%", textAlign: "left", fontSize: "0.7em"}}>
                      {shortlensummary(book.summary)}
                    </div>
                    <div style={{width: "100%", textAlign: "left", fontSize: "0.7em"}}>
                      {book.author}
                    </div>
                    <div style={{width: "100%", textAlign: "left", fontSize: "0.7em"}}>
                      ISBN: {book.isbn13}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div style={{ width: "60%", textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={closeBookList}
              style={{ padding: "6px 12px", margin: "0 auto" }}
              size="small"
            >
              Close
            </Button>
          </div>
          <div style={{ width: "40%", textAlign: "right" }}>
            <IconButton
              onClick={openInfoModal}
              color="primary"
              size="small"
              style={{ padding: "6px 12px" }}
            >
              <InfoIcon />
            </IconButton>
          </div>
        </div>
        
      </Modal>

      {/* アプリ情報モーダル */}
      <Modal
        isOpen={infoModalOpen}
        onRequestClose={closeInfoModal}
        contentLabel="AppInfo"
        className="library-modal-content"
        overlayClassName="library-modal-overlay"
      >
        <div style={{width: "100%", textAlign: "center", fontSize: "1.4em", fontWeight: 'bold' }}>
          オフィス図書館
        </div>
        <div style={{ display: "box", width: "320px", padding: "5px", maxHeight: "380px", overflowY: "auto", textAlign: "left" }}>
          <div style={{width: "100%",fontSize: "0.9em", marginTop: "10px" }}>
            <div style={{textAlign: "left", fontWeight: 'bold', marginBottom: "5px" }}>
              ＜利用ルールについて＞
            </div>
            <div style={{ textAlign: "left" }}>
              ・貸出期間：2週間（延長1回まで可能）<br/>
              ・貸出冊数：2冊まで<br/>
              &nbsp;※その他ルールは書棚の説明書をご確認ください。<br/>
            </div>
          </div>
          <div>
            <div style={{ width: "100%",fontSize: "0.9em", marginTop: "10px" }}>
              <div style={{ textAlign: "left", fontWeight: 'bold', marginBottom: "5px" }}>
              ＜機能について＞
              </div>
              <div style={{ textAlign: "left" }}>
              ・貸出可能な書籍情報の確認<br/>
              ・書籍の貸出、延長、返却の手続き<br/>
              </div>
            </div>
            <div style={{ width: "100%",fontSize: "0.9em", marginTop: "10px" }}>
              <div style={{ textAlign: "left", fontWeight: 'bold', marginBottom: "5px" }}>
                ＜各種手続きの方法について＞
              </div>
              <div style={{ textAlign: "left" }}>
                <div>
                  ※各種手続きには、手元に書籍が必要です。<br/>
                  ※貸出、返却操作は本社の書棚にて実施してください。
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div>
                    ■貸出手順<br/>
                    ①画面上部[貸出／返却]ボタンを押す<br/>
                    ②書籍背面バーコードをスキャン<br/>
                    ③[借用]＞[借りる]を押す<br/>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    ■延長手順<br/>
                    ①画面上部「貸出／返却」ボタンを押す<br/>
                    ②書籍背面バーコードをスキャン<br/>
                    ③[延長]＞[延長する]を押す<br/>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    ■返却手順<br/>
                    ①画面上部「貸出／返却」ボタンを押す<br/>
                    ②書籍背面バーコードをスキャン<br/>
                    ③[返却]を押す<br/>
                    ④書棚QRコード(※)をスキャン<br/>
                    ⑤[返却する]を押す<br/>
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    ----------<br/>
                    ※書棚QRコードは書棚のオフィス図書館プレートに記載されています。<br/>
                    ※貸出、延長手続き後、利用者の予定表に返却予定日イベントが追加されます。
                    このイベントは自動削除されないため、必要に応じて、利用者にて手動削除をお願いします。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={closeInfoModal}
            style={{ marginTop: '10px'}}
            size="small"
          >
            CLOSE
          </Button>
        </div>
      </Modal>

      {/* 書籍情報モーダル */}
      <Modal
        isOpen={bookDetailOpen}
        onRequestClose={closeBookDetail}
        contentLabel="BookDetail"
        className="library-modal-content"
        overlayClassName="library-modal-overlay"
      >
        {selectedBook && (
          <>
            <div style={{ maxHeight: '420px' }}>
              <div style={{display: "box", inlineSize: "330px"}}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div 
                    style={{ 
                      width: "60px",
                      padding: "5px 10px",
                      textAlign: "center",
                      fontSize: "1em", 
                      marginTop: "10px",
                      marginBottom: "10px", 
                      borderRadius: "5px",
                      color: "white",
                      backgroundColor: 
                        availability === 'onLoan' ? 'orange' : 
                        availability === 'free' ? 'green' : 
                        'gray',
                    }}
                  >
                    {availability === 'onLoan' && '貸出中' }
                    {availability === 'free' && '貸出可'}
                    {!['onLoan', 'free'].includes(availability) && '準備中'}
                  </div>
                  <div style={{ marginLeft: '10px', marginTop: '14px', height: '39px' }}>
                  {availability === 'onLoan' && (
                    <>
                    返却予定日: {returnDate}
                    </>
                  )}
                  </div>
                </div>
                <div style={{width: "100%", textAlign: "left", fontWeight: "bold", fontSize: "1.2em"}}>
                  {selectedBook.title}
                </div>
                <div style={{width: "100%", textAlign: "left", fontSize: "0.9em", marginLeft: "5px"}}>
                  {selectedBook.author}
                </div>
                <hr width="100%" align="left"/>
                <div style={{ display: "flex", marginTop: "5px" }}>
                  <div>
                    <div>
                      <img src={selectedBook.bookImage} alt="" width="90" height="140" style={{ border: '1px solid #d3d3d3', borderRadius: '3px' }} />
                    </div>
                    <div style={{width: "100%", textAlign: "left", fontSize: "0.8em", marginLeft: "5px", marginTop: '10px'}}>
                      ISBN:<br />
                      {selectedBook.isbn13}
                    </div>

                  </div>
                  <div style={{display: "box"}}>
                    <div style={{width: "100%", textAlign: "left", fontSize: "0.9em", marginLeft: "5px", maxHeight: '240px', overflowY: 'auto' }}>
                      {selectedBook.summary}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={closeBookDetail}
                style={{ padding: '6px 12px', margin: '0 10px', marginTop: '15px' }}
                size="small"
              >
                Close
              </Button>
            </div>
          </>
        )}
      </Modal>

      {/* バーコードスキャンモーダル */}
      <Modal
        isOpen={barcodeModalOpen}
        onRequestClose={closeBarcodeModal}
        contentLabel="BarcodeScan"
        className="library-modal-content"
        overlayClassName="library-modal-overlay"
      >
        <BarcodeReader
          onRequestClose={closeBarcodeModal}
          setBarcode={setBarcode}
          onCheckoutBookOpen={openCheckoutBookModal}
          onExtendBookOpen={openExtendBookModal}
          onReturnBookOpen={openReturnBookModal}
          parameters={parameters}
        />
      </Modal>
      
      {/* 書籍貸出モーダル */}
      <Modal
        isOpen={checkoutBookModalOpen}
        onRequestClose={closeCheckoutBookModal}
        contentLabel="CheckoutBook"
        className="library-modal-content"
        overlayClassName="library-modal-overlay"
      >
        {barcode && (
          <CheckoutBook
            onRequestClose={closeCheckoutBookModal}
            libraryId={libraryId}
            barcode={barcode}
            bookTitle={bookTitle}
            startDate={startDate}
            endDate={endDate}
            parameters={parameters}
          />
        )}
      </Modal>

      {/* 書籍延長モーダル */}
      <Modal
        isOpen={extendBookModalOpen}
        onRequestClose={closeExtendBookModal}
        contentLabel="ExtendBook"
        className="library-modal-content"
        overlayClassName="library-modal-overlay"
      >
        {barcode && (
          <ExtendBook
            onRequestClose={closeExtendBookModal}
            libraryId={libraryId}
            barcode={barcode}
            bookTitle={bookTitle}
            parameters={parameters}
          />
        )}
      </Modal>

      {/* 書籍返却モーダル */}
      <Modal
        isOpen={returnBookModalOpen}
        onRequestClose={closeReturnBookModal}
        contentLabel="ReturnBook"
        className="library-modal-content"
        overlayClassName="library-modal-overlay"
      >
        {barcode && (
          <ReturnBook
            onRequestClose={closeReturnBookModal}
            libraryId={libraryId}
            barcode={barcode}
            bookTitle={bookTitle}
            parameters={parameters}
          />
        )}
      </Modal>

    </div>
  );
};

export default OfficeLibrary;
