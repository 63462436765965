import { Providers } from '@microsoft/mgt-element';
import React, { useState, useEffect } from 'react';
import { Popup, Tooltip, Rectangle, useMap } from 'react-leaflet'

import LocationDialog from './LocationDialog';
import EventSearchUrl from './EventSearchDate';
import CustomMarker from './UserMarker';

const deskNameColor = {
  "free": "rgb(240, 248, 255)",
  "reserved": "rgb(240, 248, 255)",
  "myseat": "rgb(30, 30, 30)",
  "": "rgb(30, 30, 30)"
}

const locationColor = {
  "office": "rgb(221,235,246)",
  "remote": "rgb(252,229,214)",
  "off": "rgb(235,235,235)",
  "free": "rgb(0, 108, 201, 0.8)",
  "shareroom": "rgb(255, 255, 255, 0)",
  "reserved": "rgb(0, 108, 201, 0.8)",
  "myseat": "rgb(252,229,214)",
}

const locationBorderColor = {
  "myseat": "rgb(255, 99, 71)",
  "": "white"
}

const defaultTooltipfontSize = 1.0;


// 席の利用者をマッピングする
export default function HotDeskMaker(props){  
  const [hotDeskLocationData, setHotDeskLocationData] = useState(null);
  const [zoomFontSize, setZoomFontSize] = useState(null);
  const [zoomFontWeight, setZoomFontWeight] = useState(null);
  const [reservedAction, setReservedAction] = useState(false);

  const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

  const selectDate = (new Date(props.selectDate)).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }).split(' ')[0];
  const selectedLayer = props.selectedLayer;

  const CustomTooltip = ({ deskid, deskshare, username, deskname, workstat, desktype }) => {
    const map = useMap();

    const changeFontSize = ({zoomlevel}) => {
      
      let fontSize; 
      if (zoomlevel <= -1){
        return (null);
      }
      else if (zoomlevel >= 0 && zoomlevel < 1){
        fontSize = defaultTooltipfontSize + (zoomlevel*0.15);
        return(fontSize + 'em');
      }
      else if (zoomlevel > -1 && zoomlevel < 0 ){
        fontSize = defaultTooltipfontSize + (zoomlevel*0.6);
        return(fontSize + 'em');
      }
      else{
        return ((zoomlevel * 1.2) + 'em');
      }

    }

    useEffect(() => {
      const updateFontSize = () => {
        const currentZoom = map.getZoom();
        const fontSize = changeFontSize({zoomlevel:currentZoom});
        const fontWeight =  currentZoom < 2 ? 'bold' : '';

        setZoomFontSize(fontSize);
        setZoomFontWeight(fontWeight);
      };

      updateFontSize();
      map.on('zoom', updateFontSize);

      return () => {
        map.off('zoom', updateFontSize);
      }

    },[map]);

    if (!zoomFontSize || username === 'free'){
      return;
    }

    const deskName = () => {
      if (desktype !== 'Z' && deskshare === 'hot'){
        // if (workstat === 'reserved' || workstat === 'myseat'){
        //   return username;
        // }else{
          return deskid;
        // }
      }else{
        if (deskname){
          const desknameSep = deskname.match(/.{1,6}/g);
          if (desknameSep){
            const newtext = desknameSep.join('\n');
            return newtext;
          }
        }else{
          let tooltipName = username.length > 10 ? username.slice(0, 8) + '...' : username;
          return tooltipName;
        }
      }
    }

    return (
      <>
        <Tooltip className='tooltip_username_shadow_off' direction="center" offset={[0, 0]} permanent={true} opacity={1}>
          <div style={{ fontSize: zoomFontSize, color: deskNameColor[workstat], fontWeight: zoomFontWeight, whiteSpace: "pre"}}>
            {deskName()}
          </div>
        </Tooltip>
      </>
    );
  };

  // フリーアドレス予約
  const CustomPopup = ({ deskid, deskshare, desktype, workstat, selectDate, selectedAmPm, parameters}) => {
    const day = selectDate ? selectDate : new Date();
    const amPm = selectedAmPm ? selectedAmPm : 'AllDay';
    // console.log('selectDay/AmPm::',day,amPm);

    if (deskshare === 'hot' && workstat ==='free'){
      return (
        <>
          <Popup autoPan={true}>
            <LocationDialog deskid={deskid} selectDay={day} selectAmPm={amPm} setReservedAction={setReservedAction} parameters={parameters}/>
          </Popup>
        </>
      );
    }
  };


  // -----------
  const today = () => {
    if (props.hotDesking){
      if (props.selectDate){
        return props.selectDate;
      }else{
        return new Date();
      }
    }else{
      return new Date();
    }
  }
  
  const zoomIconSize = ({zoomlevel, iconSize}) => {
    if (zoomlevel >= 0 && zoomlevel < 1){
      return([iconSize[0]*(zoomlevel+1), iconSize[1]*(zoomlevel+1)]);
    }
    else if (zoomlevel > -1 && zoomlevel < 0 ){
      return([iconSize[0]+(iconSize[0]*zoomlevel*0.5555), iconSize[1]+(iconSize[1]*zoomlevel*0.5555)]);
    }
    else{
      return([iconSize[0] * zoomlevel * 2, iconSize[1] * zoomlevel * 2]);
    }
  }

  const initIconSize = props.zoomlevel > -1 ? zoomIconSize({zoomlevel: props.zoomlevel, iconSize: props.iconSize}) : [-1, -1];  
  const [iconSize, setIconSize] = useState(initIconSize);

  // Create a custom icon with the specified size
  const map = useMap();

  useEffect(() => {

    const updateIconSize = () => {
      const currentZoom = map.getZoom();
      const updatedSize = zoomIconSize({zoomlevel:currentZoom, iconSize:props.iconSize});
      setIconSize(updatedSize);
    };

    map.on('zoom', updateIconSize);

    return () => {
      map.off('zoom', updateIconSize);
    };

  }, [map]);

  // ---------------------------------

  // フリーアドレス席描画
  useEffect(() => {

    const fetchHotDeskData = async () => {
      try {

        const token = await Providers.globalProvider.getAccessToken();

        const meResponse = await fetch(`https://graph.microsoft.com/v1.0/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (meResponse.ok) {
          const meData = await meResponse.json();
          const workSpaceResponse = await fetch(props.workSpaceURL, {
            headers: {
              'Authorization': AUTH_TOKEN
            }
          });

          if (workSpaceResponse.ok){
            const workSpace = await workSpaceResponse.json();
          
            const hotDeskPromises = workSpace.value.map(async (wslist) => {

              if (wslist.desktype !== 'Z' && wslist.userid !== 'free'){ 

                if (wslist.deskshare === 'hot'){
                  const searchDate = () => {
                    if (props.selectDate){
                      return props.selectDate;
                    }else{
                      return new Date();
                    }
                  }
                  
                  const eventOrganizerUrl = EventSearchUrl(wslist.userid,'select',searchDate(),"",props.selectedAmPm);
                  // console.log('HDM-eventOrganizerUrl::',eventOrganizerUrl);

                  const eventOrganizerResponse = await fetch(eventOrganizerUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                      },
                  });
                  
                  if (eventOrganizerResponse.ok) {
                    const eventOrganizerData = await eventOrganizerResponse.json();

                    if (eventOrganizerData.value.length > 0){
                      const reservedCheck = eventOrganizerData.value.length === 2 && eventOrganizerData.value[1].organizer.emailAddress.address === meData.userPrincipalName ? 1 : 0;
                      const userResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${eventOrganizerData.value[reservedCheck].organizer.emailAddress.address}`, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      });
                      
                      if (userResponse.ok) {
                        const userData = await userResponse.json();

                        let photoURL;
                        if (userData.surname && userData.givenName){
                          photoURL = `https://ui-avatars.com/api/?name=${encodeURIComponent(userData.surname)}+${encodeURIComponent(userData.givenName)}&size=100&rounded=true&background=F5F7F9&color=4B4B4B`;
                        }

                        const reservedDesk = () => {
                          if (userData.id === meData.id){
                            return 'myseat';
                          }else{
                            return 'reserved';
                          }
                        }

                        return {
                          deskId: wslist.deskid,
                          deskType: wslist.desktype,
                          position: wslist.position,
                          workStat: reservedDesk(),
                          userId: userData.id,
                          userName: userData.displayName,
                          deskName: '',
                          deskShare: wslist.deskshare,

                          photo: photoURL,
                          work: "clockin",
                          workspace: { deskid: wslist.deskid, desktype: wslist.desktype, position: wslist.position, workstat: "office" },
                          searchDate: today(),
                          hotDesk : reservedDesk()
                        };
                      }else{
                        console.error(`userProfileResponse::Failed to fetch user data for user ${wslist.userid}`);
                        // return null;
                      }
                    }
                  }

                  if (wslist.desktype !== 'Z'){
                    return {
                      deskId: wslist.deskid,
                      deskType: wslist.desktype,
                      position: wslist.position,
                      workStat: wslist.workstat,
                      userId: wslist.userid,
                      userName: wslist.username,
                      deskName: wslist.diplayname,
                      deskShare: wslist.deskshare,

                      photo: '',
                      work: "off",
                      workspace: { deskid: wslist.deskid, desktype: wslist.desktype, position: wslist.position, workstat: "office" },
                      searchDate: today(),
                      hotDesk : ''
                    }
                  }

                }

              }

            });

            // マーカー情報をセット
            const hotDeskingResults = await Promise.all(
              hotDeskPromises.map((promise) => promise || { userId: '', presence: {}, displayName: '', work: '', workspace: {deskid: '', desktype: '', position: '', workstat: ''}})
            );

            setHotDeskLocationData(hotDeskingResults);
            
          }
        }

      } catch (error) {
        console.error('Error fetching warkspace data', error);
      }
    };
    fetchHotDeskData()

  // }, [selectedLayer, selectDate, reservedAction, props.reservedCancel]);
  }, [selectedLayer, selectDate, reservedAction, props.reservedCancel, props.selectedAmPm]);

  if (hotDeskLocationData === null ){
    return
  }

  const localtionBounds = ({desktype, bounds}) => {
    if (desktype === 'Z'){
      const centerPosition = [(bounds[1][0] - bounds[0][0]) /2 + bounds[0][0], ((bounds[1][1] - bounds[0][1]) /2 + bounds[0][1])];
      const toolTipsPosition = [[centerPosition[0]-10, centerPosition[1]-10],[centerPosition[0]+10, centerPosition[1]+10]];
      
      return toolTipsPosition;
    }
    return bounds;
  }

  const WorkspaceLocation = ({wsData,index}) => {

    if (wsData === undefined){
      return;
    }

    return (
      <>
        <Rectangle
          key={index}
          bounds={localtionBounds({desktype:wsData.deskType, bounds:wsData.position})}
          pathOptions={
            {
              color: locationBorderColor[wsData.workStat],
              fillColor: locationColor[wsData.workStat],
              fillOpacity: '1',
              weight: '1',
              radius: '30'
            }
          }
        >
          <CustomTooltip
            deskid={wsData.deskId}
            deskshare={wsData.deskShare}
            username={wsData.userName}
            deskname={wsData.deskName}
            workstat={wsData.workStat}
            desktype={wsData.deskType}
          />
          <CustomPopup
            userid={wsData.userId}
            deskid={wsData.deskId}
            deskshare={wsData.deskShare}
            workstat={wsData.workStat}
            desktype={wsData.deskType}
            selectDate={props.selectDate}
            selectedAmPm={props.selectedAmPm}
            parameters={props.parameters}
          />
        </Rectangle>

        <CustomMarker
          key={wsData.deskId}
          userData={wsData}
          index={index}
          iconSize={iconSize}
          hotDesking={props.hotDesking}
          selectDate={props.selectDate}
          selectedAmPm={props.selectedAmPm}
          zoomlevel={props.zoomlevel}
          parameters={props.parameters}
        />
      </>
    )

  }
  
  return (
    <>
      {hotDeskLocationData !== undefined && (
        hotDeskLocationData.map((wsData, index) => (
          <WorkspaceLocation key={index} wsData={wsData} index={index} iconSize={iconSize} props={props}/>
        ))
      )}
    </>
  );
}

