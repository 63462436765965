// import React, { useState } from 'react';


export default function  EventSearchUrl(userid, searchReq, searchDate, urlType, selectedAmPm ){
 
  if (searchReq === 'used'){
    // 現在の日付を取得し、ISO 8601 形式にフォーマットする
    const today = new Date();
    const nowDate = today.getFullYear() +'-'+ ('0' + (today.getMonth()+1)).slice(-2) +'-'+ ('0' + today.getDate()).slice(-2);
    const startDate = today.toISOString()
    const endDate = nowDate +' 23:59:59';
    const startDateTimeUtc = new Date(startDate).toISOString();
    const endDateTimeUtc = new Date(endDate).toISOString();

    const orderbyOption = "$orderby=isAllDay desc,start/dateTime&startdatetime=" + startDateTimeUtc + "&enddatetime=" + endDateTimeUtc;
    const selectOption = "$select=id,subject,start,end,location,attendees,isAllDay,organizer";

    if (urlType === 'short'){
      return "/users/" + userid +"/calendarView?" + orderbyOption + "&" + selectOption
    }else{
      return "https://graph.microsoft.com/v1.0/users/" + userid +"/calendarView?" + orderbyOption + "&" + selectOption
    }

  }
  else if (searchReq === 'select' && searchDate){
    const selectDay = new Date(searchDate);

    // 指定された1日分の予定を表示する
    const selectDate = selectDay.getFullYear() +'-'+ ('0' + (selectDay.getMonth()+1)).slice(-2) +'-'+ ('0' + selectDay.getDate()).slice(-2);
    // const startDate = selectDate +' 00:00:00.001';
    // const endDate = selectDate +' 23:59:59';
    const startDate = selectedAmPm === 'PM' ? `${selectDate} 13:00:00.001` : `${selectDate} 00:00:00.001`;
    const endDate = selectedAmPm === 'AM' ? `${selectDate} 12:59:59` : `${selectDate} 23:59:59`;
    const startDateTimeUtc = new Date(startDate).toISOString();
    const endDateTimeUtc = new Date(endDate).toISOString();

    const orderbyOption = "$orderby=isAllDay desc,start/dateTime&startdatetime=" + startDateTimeUtc + "&enddatetime=" + endDateTimeUtc;
    const selectOption = "$select=id,subject,start,end,location,attendees,isAllDay,organizer";

    if (urlType === 'short'){
      return "/users/" + userid +"/calendarView?" + orderbyOption + "&" + selectOption
    }else{
      return "https://graph.microsoft.com/v1.0/users/" + userid +"/calendarView?" + orderbyOption + "&" + selectOption
    }

  }
  else{
    const eventNowflag = true; // [true]:現在時刻以降の予定を表示する場合、[false]:当日1日分の予定を表示する場合

    // 現在の日付を取得し、ISO 8601 形式にフォーマットする
    const today = new Date();
    const nowDate = today.getFullYear() +'-'+ ('0' + (today.getMonth()+1)).slice(-2) +'-'+ ('0' + today.getDate()).slice(-2);
    const startDate = eventNowflag ? today.toISOString() : (nowDate +' 00:00:00.001'); // 現在時刻以降の予定を表示する場合 : 当日1日分の予定を表示する場合
    const endDate = nowDate +' 23:59:59';
    const startDateTimeUtc = new Date(startDate).toISOString();
    const endDateTimeUtc = new Date(endDate).toISOString();

    const orderbyOption = "$orderby=isAllDay desc,start/dateTime&startdatetime=" + startDateTimeUtc + "&enddatetime=" + endDateTimeUtc;
    const selectOption = "$select=id,subject,start,end,location,attendees,isAllDay,organizer";

    if (urlType === 'short'){
      return "/users/" + userid +"/calendarView?" + orderbyOption + "&" + selectOption
    }else{
      return "https://graph.microsoft.com/v1.0/users/" + userid +"/calendarView?" + orderbyOption + "&" + selectOption
    }
  }

}

