// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  color: white;
  text-align: center;
}

.signin-container div {
  margin-bottom: 20px;
}

.signin-container .login-button {
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  /* background-color: #4CAF50; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signin-container .login-button:hover {
  background-color: #45a049;
}

.custom-location-avatar {
  position: absolute;
  /* position: relative; */
  top: 18px;
  right: 20px;
}

.avatar-icon .person {
  position: relative;
  z-index: 1000;
  /* background-color: #45a049; */
  /* border-radius: 50%; */
  --person-avatar-size: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,WAAW;EACX,4DAA4D;AAC9D;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,uDAAuD;EACvD,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,+BAA+B;EAC/B,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,+BAA+B;EAC/B,wBAAwB;EACxB,0BAA0B;AAC5B","sourcesContent":["body, html {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  width: 100%;\n  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n}\n\n.signin-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100svh;\n  background: linear-gradient(to right, #ff7e5f, #feb47b);\n  color: white;\n  text-align: center;\n}\n\n.signin-container div {\n  margin-bottom: 20px;\n}\n\n.signin-container .login-button {\n  padding: 10px 20px;\n  font-size: 18px;\n  color: white;\n  /* background-color: #4CAF50; */\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.signin-container .login-button:hover {\n  background-color: #45a049;\n}\n\n.custom-location-avatar {\n  position: absolute;\n  /* position: relative; */\n  top: 18px;\n  right: 20px;\n}\n\n.avatar-icon .person {\n  position: relative;\n  z-index: 1000;\n  /* background-color: #45a049; */\n  /* border-radius: 50%; */\n  --person-avatar-size: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
