import React, { useState } from 'react';
import { Providers } from '@microsoft/mgt-element';
import { CircularProgress, Button, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as microsoftTeams from "@microsoft/microsoft-graph-client";


const ExtendBook = ({ onRequestClose, libraryId, barcode, bookTitle, parameters }) => {
  const [returnComplete, setReturnComplete] = useState(false);
  const [returnStatus, setReturnStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loanPeriod, setLoanPeriod] = useState('');

  const extendSuccessMessage = '以下の貸出延長が完了しました。';
  const extendFailureMessage = '以下の貸出延長はできませんでした。再度、延長操作を行ってください。';
  
  const bookId = `${barcode}@${parameters['accountDomian']}`;


  const handleCancel = () => {
    // 確認画面で「キャンセル」ボタンがクリックされたときの処理
    // 確認画面を非表示にする
    onRequestClose();
  };

  const handleEventExtendConfirm = async () => {
    setLoading(true);
    try {
      const accessToken = await Providers.globalProvider.getAccessToken();
      const client = await microsoftTeams.Client.init({
        authProvider: (done) => {
          done(null, accessToken);
        }
      });

      const bookEvents = await client.api(`/users/${bookId}/events`).get();

      if (bookEvents.value.length > 0){
        const eventStartDate = new Date(bookEvents.value[0].start.dateTime);
        const startDateLocal = new Date(eventStartDate.getTime() + ((9 * 60) * 60 * 1000));
        const startDate = startDateLocal.getFullYear() +'-'+ ('0' + (startDateLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + startDateLocal.getDate()).slice(-2);                  
        
        const eventEndDate = new Date(bookEvents.value[0].end.dateTime);
        const endDateLocal = new Date(eventEndDate.getTime() + ((9 * 60) * 60 * 1000));
        const dueDate = new Date(endDateLocal.setDate(endDateLocal.getDate() + parameters['extendDays']));
        const endDate = dueDate.getFullYear() +'-'+ ('0' + (dueDate.getMonth()+1)).slice(-2) +'-'+ ('0' + dueDate.getDate()).slice(-2);

        setLoanPeriod(`${startDate} 〜 ${endDate}`);

        const libraryEvents = await client.api(`/users/${libraryId}/events`).get();

        const matchingEvent =  await libraryEvents.value.find(event => event.iCalUId === bookEvents.value[0].iCalUId);

        if (matchingEvent){
          const event = {
            start: {
              dateTime: `${startDate}T00:00:00`,
              timeZone: 'Asia/Tokyo'
            },
            end: {
              dateTime: `${endDate}T00:00:00`,
              timeZone: 'Asia/Tokyo'
            }
          };

          await client.api(`/users/${libraryId}/events/${matchingEvent.id}`).patch(event);

          const endDateEndLocal = new Date(dueDate.getTime() + ((24 * 60) * 60 * 1000) + ((9 * 60) * 60 * 1000));
          const endDateEnd = endDateEndLocal.getFullYear() +'-'+ ('0' + (endDateEndLocal.getMonth()+1)).slice(-2) +'-'+ ('0' + endDateEndLocal.getDate()).slice(-2);
          
          const userReturnedEvent = {
            subject: `返却期限：${bookTitle}`,
            start: {
              dateTime: `${endDate}`,
              timeZone: 'Asia/Tokyo'
            },
            end: {
              dateTime: `${endDateEnd}`,
              timeZone: 'Asia/Tokyo'
            },
            isAllDay: 'true',
            showAs: 'free',
            responseRequested: 'false',
          };

          // const  userReturnedEventResponse= await client.api('/me/events').post(userReturnedEvent);
          // console.log('CB-responseData::',userReturnedEventResponse);
          await client.api('/me/events').post(userReturnedEvent);
          
          // extened loan log
          const logItemData = await client.api(`/sites/${parameters['siteId']}/lists/${parameters['listId']}/items`)
	          .header('Prefer','allowthrottleablequeries')
	          .filter(`fields/eventID eq '${matchingEvent.id}'`)
	          .get();

          if (logItemData){
            const checkoutEvent = {
              fields: {
                checkoutEndDate: `${endDate}`,
                status: 'extended'
              }
            };

            await client.api(`/sites/${parameters['siteId']}/lists/${parameters['listId']}/items/${logItemData.value[0].id}`)
              .patch(checkoutEvent);

          }else{
            console.log('no maching logItem::',matchingEvent.id);
          };

        }else{
          console.log('no maching event::',matchingEvent.id);
        }

      }

      setReturnComplete(true);
      setReturnStatus(extendSuccessMessage);
      setLoading(false);

    } catch (error) {
      console.error('Error POST data', error);
      setReturnComplete(true);
      setReturnStatus(extendFailureMessage);
      setLoading(false);
    }
  };

  return (
    <>
      {returnComplete ? (
        <div>
          <p>{returnStatus}</p>
          <div style={{width: "100%", textAlign: "left", marginTop: '10px'}}>
            {bookTitle}
          </div>
          <div style={{width: "100%", textAlign: "left", marginTop: '5px'}}>
            期間: {loanPeriod}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={onRequestClose}
            style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
            size="small"
          >
            CLOSE
          </Button>
        </div>
      ) : (
        <>
          {loading ? (
            <>
              <CircularProgress />
              <p>処理中…</p>
            </>
          ) : (
            <>
              <Box flexDirection="row" justifyContent="flex-end" display="flex">
                <IconButton
                  color="primary"
                  onClick={handleCancel}
                  style={{ padding: '0px 0px' }}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box display="box">
                <div>
                  <div style={{width: "100%", textAlign: "left", marginTop: '10px'}}>
                    {bookTitle}
                  </div>
                  <div style={{width: "100%", textAlign: "left", fontSize: "0.9em", marginTop: '5px', marginBottom: '10px'}}>
                    ISBN: {barcode}
                  </div>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEventExtendConfirm}
                  style={{ padding: '6px 12px', marginTop: '20px', marginRight: '5px' }}
                  size="small"
                >
                  延長する
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCancel}
                  style={{ padding: '6px 12px', marginTop: '20px', marginLeft: '5px' }}
                  size="small"
                >
                  CANCEL
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ExtendBook;
