import React, { useState } from 'react';
import { Button } from '@mui/material';

import ReservationConfirmationModal from './ReservationConfirmationModal';

const LocationDialog = ({ deskid, selectDay, selectAmPm, setReservedAction, parameters }) => {
        
  // 予約開始日を指定する
  const date1 = new Date(selectDay);
  const reservedDate = date1.getFullYear() +'-'+ ('0' + (date1.getMonth()+1)).slice(-2) +'-'+ ('0' + date1.getDate()).slice(-2);
  const startDate = reservedDate +'T00:00:00';

  // 予約終了日を指定する
  const date2 = new Date(selectDay);
  const date3 = new Date(date2.setDate(date2.getDate() +1));
  const endDate = date3.getFullYear() +'-'+ ('0' + (date3.getMonth()+1)).slice(-2) +'-'+ ('0' + date3.getDate()).slice(-2) +'T00:00:00';

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [reservationComplete, setReservationComplete] = useState(false);

  const handleReserveClick = () => {
    // 予約ボタンがクリックされたとき、確認画面を表示
    setShowConfirmation(true);
  };

  const handleConfirmReservation = () => {
    // 完了画面を表示する
    setReservationComplete(true);
  };

  const handleCancelReservation = () => {
    // 確認画面で「キャンセル」ボタンがクリックされたときの処理
    // 確認画面を非表示にする
    setShowConfirmation(false);
  };

  const handleCloseModal = () => {
    // 確認画面で「閉じる」ボタンがクリックされたときの処理
    setShowConfirmation(false);
    setReservationComplete(false);
    setReservedAction(prev => !prev);
  };

  const selectTime = (time) => {
    if (time === 'allday'){
      return '終日';
    }
    else if (time === 'am'){
      // return 'AM';
      return '9:00-12:00';
    }
    else if (time === 'pm'){
      // return 'PM';
      return '13:00-17:30';
    }
    else{
      return time;
    }
  };

  return (
    <div style={{ display: 'box', justifyContent: 'flex-start', marginBottom: '10px' }}>
      <div style={{ textAlign: 'left' }}>
        <div>
          予約日：<strong>{reservedDate}</strong>
        </div>
        <div>
          時間：<strong>{selectTime(selectAmPm)}</strong>
        </div>
        <div>
          場所：<strong>{deskid}席</strong>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <div style={{ margin: '10px 0' }}>
          <img src='./images/free.png' alt="User" style={{ maxWidth: '50%', maxHeight: '50%'}} />
        </div>
        <div>
          <Button
              variant="contained"
              color="primary"
              onClick={handleReserveClick}
              style={{ padding: '6px 12px', marginTop: '0px', marginLeft: '5px' }}
              size="small"
            >
              座席予約
            </Button>
        </div>
      </div>

      {/* 予約確認モーダル */}
      <ReservationConfirmationModal
        isOpen={showConfirmation}
        onRequestClose={handleCloseModal}
        onConfirm={handleConfirmReservation}
        onCancel={handleCancelReservation}
        reservedDate={reservedDate}
        reservedAmPm={selectAmPm}
        startDate={startDate}
        endDate={endDate}
        deskid={deskid}
        userPrincipalName=""
        displayName=""
        worktype=""
        parameters={parameters}
      />
    </div>
  );
};

export default LocationDialog;