import { Providers } from '@microsoft/mgt-element';
import { CacheService } from '@microsoft/mgt-react';
import React, { useState } from 'react';
import { Popup, Marker } from 'react-leaflet';

import Leaflet from "leaflet";
import UserDialog from './UserDialog';
import EventSearchUrl from './EventSearchDate';

CacheService.config.users.invalidationPeriod = 30000; // 30 seconds
CacheService.config.presence.invalidationPeriod = 30000; // 30 seconds

const transparentImage = './images/transparent.png';
// const transparentImage = './images/icon_business_man01.png';


const userPresence = {
  "Available" : "makericon_green",
  "AvailableIdle" : "makericon_green",
  "Busy" : "makericon_red",
  "BusyIdle" : "makericon_red",
  "DoNotDisturb" : "makericon_red",
  "BeRightBack" : "makericon_orange",
  "Away" : "makericon_orange",
  "Offline" : "makericon_gray",
  "reserved": "makericon_gray",
  "myseat": "makericon_myseat",
  "reservedAllday" : "makericon_reserved_allday",
  "reservedAm" : "makericon_reserved_am",
  "reservedPm" : "makericon_reserved_pm",
  "none" : "",
  "" : "makericon_gray",
};

// <UserMaker zoomlevel={defaultZoom} iconSize={defaultIconSize} hotDesking={false} selectDate={selectedDate}/>

export default function CustomMarker(props){
  
  const presence = () => {
    if (props.hotDesking){
      if (props.userData.hotDesk === 'reserved' || props.userData.hotDesk === 'myseat'){
        return props.userData.hotDesk;
      }else{
        return 'none';
      }
    }else{
      return 'none';
    }
  }

  //props.hotDesking
  const photo = () => {
    if (props.userData.workStat === 'equipment'){
      // return './images/icon_business_man01.png';
      return transparentImage;
    }else{
      if (props.hotDesking && props.userData.photo){
        return props.userData.photo;
      }else{
        return transparentImage;
      }
    }
  }
  
  // Create a custom icon with the specified size
  const customIcon = new Leaflet.Icon({
    iconUrl: photo(),
    iconSize: [props.iconSize[0],props.iconSize[1]],
    iconAnchor: [props.iconSize[0]/2, props.iconSize[1]/2],
    className: userPresence[presence()],
  });

  if (props.userData.workspace.position[0] === undefined || props.iconSize[0] < 1 || props.userData.workStat === 'free'){
    return;
  }

  const iconOffsetA = [7, 2];
  const iconOffsetB = [13, -2];
  const iconOffsetZ = [5, -5];

  let markerPosition = [];
  if (props.hotDesking && (props.userData.hotDesk !== 'reserved' && props.userData.hotDesk !== 'myseat')){
    markerPosition = [
      ((props.userData.workspace.position[1][0] - props.userData.workspace.position[0][0]) /2 + props.userData.workspace.position[0][0]) + iconOffsetZ[0],
      ((props.userData.workspace.position[1][1] - props.userData.workspace.position[0][1]) /2 + props.userData.workspace.position[0][1]) + iconOffsetZ[1]
    ];
  }else{
    if (props.userData.workspace.desktype === 'A'){
      markerPosition = [
        (props.userData.workspace.position[0][0]-iconOffsetA[0]),
        ((props.userData.workspace.position[1][1] - props.userData.workspace.position[0][1]) /2 + props.userData.workspace.position[0][1] - iconOffsetA[1])
      ];
    }else if (props.userData.workspace.desktype === 'B'){
      markerPosition = [
        (props.userData.workspace.position[1][0]+iconOffsetB[0]),
        ((props.userData.workspace.position[1][1] - props.userData.workspace.position[0][1]) /2 + props.userData.workspace.position[0][1] + iconOffsetB[1])
      ];
    }else{
      markerPosition = [
        ((props.userData.workspace.position[1][0] - props.userData.workspace.position[0][0]) /2 + props.userData.workspace.position[0][0]) + iconOffsetZ[0],
        ((props.userData.workspace.position[1][1] - props.userData.workspace.position[0][1]) /2 + props.userData.workspace.position[0][1]) + iconOffsetZ[1]
      ];
    }
  }

  return (
    <CustomUserMakerIcon
      userid={props.userData.userId}
      work={props.userData.work}
      icon={customIcon}
      position={markerPosition}
      desktype={props.userData.workspace.desktype}
      selectDate={props.selectDate}
      hotDesking={props.hotDesking}
      workStat={props.userData.workStat}
      deskshare={props.deskshare}
      worktype={props.worktype}
      comment={props.userData.comment}
      setReservedAction={props.setReservedAction}
      parameters={props.parameters}
    />
  );
  
}

const CustomUserMakerIcon = ({
  userid,
  work,
  icon,
  position,
  desktype,
  hotDesking,
  selectDate,
  workStat,
  deskshare,
  worktype,
  comment,
  setReservedAction,
  parameters
  }) => {

  const [userPresence, setUserPresence] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [eventCount, setEventCount] = useState(null);

  const selectDay = () => {
    if (selectDate){
      return selectDate;
    }else{
      const nowDate = new Date();
      return nowDate;
    }
  }
  
  if (userid){

    const handlePopupClick = async () => {
      
      try {
        // ポップアップがクリックされたときにAPIからデータを取得
        const token = await Providers.globalProvider.getAccessToken();

        if (desktype !== 'Z'){
          const userResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${userid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (userResponse.ok) {
            const userData = await userResponse.json();

            const userPresenceResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${userData.id}/presence`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (userPresenceResponse.ok) {
              const userPresenceData = await userPresenceResponse.json();
              setUserPresence(userPresenceData.activity);

              if (userPresenceData.statusMessage){
                setStatusMessage(userPresenceData.statusMessage.message.content);
              }
              
            } else {
              console.error(`userPresenceResponse::Failed to fetch presence data for user ${userid}`);
              return null;
            }
          } else {
            console.error(`userProfileResponse::Failed to fetch user data for user ${userid}`);
            return null;
          }
        }

        if (workStat !== 'equipment'){
          const calendarViewUrl = EventSearchUrl(userid,'select',selectDate);
          // console.log('UM-calendarViewUrl:',calendarViewUrl);

          const eventsResponse = await fetch(calendarViewUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
          });

          if (eventsResponse.ok) {
            const eventsUserData = await eventsResponse.json();

            setEventCount(eventsUserData.value.length);
            // console.log('eventCount::',eventCount);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

    return (
      <>
        <Marker position={position} icon={icon} zIndexOffset={1010} eventHandlers={{ click: handlePopupClick }}>
          <Popup autoPan={true} closeOnClick={true} >
            <UserDialog
              userid={userid}
              work={work}
              workStat={workStat}
              desktype={desktype}
              userPresence={userPresence}
              userStatusMessage={statusMessage}
              userEvent={eventCount}
              searchDate={selectDay()}
              hotDesking={hotDesking}
              deskshare={deskshare}
              worktype={worktype}
              comment={comment}
              setReservedAction={setReservedAction}
              parameters={parameters}
            />
          </Popup>
        </Marker>
      </>
    );
  };
};

